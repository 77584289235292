
import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaArrowUp } from 'react-icons/fa';

import NavigationBar from './NavigationBar';
import FloatingButton from './FloatingButton';
import SuiteType from './SuiteType';


import './Pages.css';




const Negishot = () => {
  const [activeDiv, setActiveDiv] = useState(null);
  const [bgColor, setBgColor] = useState('white');

  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);
  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    
    <div className="Negishot" id="Negishot">
      <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <div className='negtext'>
          <h2>הצהרת נגישות</h2>
          <p>המקום סוויטות כפריותבע"מ אחראית על הקמת והפעלת אתר https://hamakom-suites.co.ilועל נכסים דיגיטליים נוספים. אנו רואים חשיבות רבה במתן שירות שוויוני לכלל הלקוחות והגולשים ובשיפור השירות הניתן ללקוחות ולגולשים עם מוגבלות. לפיכך אנו משקיעים משאבים רבים בהנגשת האתר שלנו על מנת להפוך אותו לזמין יותר עבור אנשים עם מוגבלות.</p>
          <h2>הסדרי נגישות במשרדנו</h2>
          <p>להלן הסדרי הנגישות הקיימים במשרדנו, אשר ממוקמים במושב נטועה:</p>
          <p>· קיימות חניות</p>
          <p>· קיים רצף גישה מהחניה דרך הכניסה ועד למשרדנו.</p>
          <p>· קיימים שירותי נכים נגישים.</p>
          <p>· קיימים שלטי זיהוי והכוונה.</p>
          <p>· הכניסה למשרדנו מיועדת לסייע לאדם עם מוגבלות.</p>
          <h3>סיוע במילוי טפסים</h3>
          <p>אנשים הזקוקים לסיוע במילוי טפסים יכולים להתקשר ולשוחח בטלפון, לחילופין אנשים עם ירידה בשמיעה המתקשים לשוחח בטלפון יכולים לפנות ולקבל סיוע בטלפון04-9806-006 או במייל hamakom12@gmail.comנשמח לסייע בכל הניתן.</p>
          <h3>הנגשת מסמכים</h3>
          <p>הנגשת המסמכים באתר מתבצעת בהתאם לרלוונטיות הקבצים ולתאריך העדכון שלהם. במידה ונתקלתם בקובץ שאינו נגיש והנכם זקוקים להתאמות נגישות, אנא פנו אלינו ונשמח לתקן ולסייע.</p>
          <h2>נגישות אתר האינטרנט</h2>
          <p>הנגשת האתר נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אנשים עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות, לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אנשים בני הגיל השלישי.</p>
          <p>אנו עושים מאמצים שהאתר שלנו יעמוד בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע"ג 2013, ברמת התקן הנדרש. כמו כן, אנו מיישמים את המלצות מסמך WCAG2.0 מאת ארגון W3C.</p>
          <p><strong>תיקונים והתאמות שבוצעו באתר:</strong></p>
          <p>· האתר מותאם לדפדפנים הנפוצים</p>
          <p>· האתר מותאם לשימוש בטלפון הסלולרי.</p>
          <p>· האתר מותאם לתצוגה תואמת מגוון מסכים ורזולוציות.</p>
          <p>· אמצעי הניווט באתר פשוטים נוחים לשימוש.</p>
          <p>· תכני האתר כתובים באופן ברור, מסודר והיררכי.</p>
          <p>· תוכן האתר כתוב בשפה פשוטה וברורה.</p>
          <p>· כל הדפים באתר בעלי מבנה קבוע.</p>
          <p>· למרבית התמונות באתר יש הסבר טקסטואלי חלופי (alt).</p>
          <p>· הקישורים באתר ברורים ומכילים הסבר לאן הם מקשרים.</p>
          <p>· אפשרות לשנות את הצבעים באתר באמצעות לחיצה על כפתור הרקע באתר.</p>
          <p>· האתר מאפשר שינוי גודל הגופן על ידי שימוש במקש Ctrl ומקש + במקלדת</p>
          <p>· אין באתר שימוש בתצוגת טקסט נע או מהבהב.</p>
          <h3>סייגים לנגישות</h3>
          <p>חשוב לציין, כי למרות מאמצינו להנגיש את כלל הדפים באתר, ולמרות פעילותינו לשפר את נגישות האתר - ייתכן שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו. אנו נעשה ככל שביכולתנו על מנת להנגיש את האתר בצורה המיטבית ולענות לפניות בצורה המקצועית והמהירה ביותר.</p>
          <h2>פניות, בקשות והצעות לשיפור בנושאי נגישות:</h2>
          <p>אם נתקלתם בבעיה בנושא נגישות באתר, נשמח אם תכתבו לנו, באמצעות פנייה לרכז הנגישות שלנו במייל hamakom12@gmail.com</p>
          <p>כדי שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים ככל שניתן:</p>
          <p>· תיאור הבעיה</p>
          <p>· מהי הפעולה שניסיתם לבצע</p>
          <p>· קישור לדף בו גלשתם</p>
          <p>· סוג הדפדפן וגרסתו</p>
          <p>· מערכת הפעלה</p>
          <p>· סוג הטכנולוגיה המסייעת (במידה והשתמשתם)</p>
          <h2>פרטי רכז הנגישות במשרדנו:</h2>
          <p>שם: רחל כהן</p>
          <p>טלפון: 04-9806-006</p>
          <p>דוא”ל: hamakom12@gmail.com</p>
          <p>הצהרת הנגישות עודכנה ביום: 08.09.2022</p>
      </div>
      <SuiteType setBgColor={setBgColor} />
      <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>
      <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

    </div>
           

  );
}

export default Negishot;