// Gallery.js
// import './Gallery.css';
import React, { useState, useEffect,useRef ,lazy, Suspense} from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SeparatorLine from '../assets/photos/sep3.png';
import { MdFullscreen } from 'react-icons/md';

const Gallery = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./Gallery.css');//replacing the css with the css of the gallery of the suites
      } catch (err) {}
    };
    importCss();
  }, []);

  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeImages, setActiveImages] = useState([]);
  const [sliderOpen, setSliderOpen] = useState(false);
  const thumbnailRefs = useRef([]);

  const isIOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'production' ? 'https://polls-service-6qrvcljmeq-uc.a.run.app' : 'http://localhost:8080';

    // Fetch categories with thumbnails from the backend
    axios.get(`${apiUrl}/api/imgcategory/`).then(response => {
      setCategories(response.data);

    thumbnailRefs.current = response.data.map(() => React.createRef()); // Initialize refs

    });
  }, []);

  // Use useEffect to preload images
  useEffect(() => {
    const imageUrlsToPreload = [
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8524.jpeg', 
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8370.jpeg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A7963.jpeg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A7967.jpeg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8580.jpeg', 
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8579.jpeg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8205.jpeg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8192.jpeg', 
      'https://storage.googleapis.com/djangobucket2023/gallery-images/52263513_2172310862807430_7206016852722450432_n.jpg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/52326628_2172311096140740_4309978649081151488_n.jpg',
      //lux suite
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8088.jpeg', 
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8109.jpeg',
      'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8117.jpeg',
      
      // Add more image URLs as needed
    ];
    // Preload images
    const preloadImages = () => {
      const promises = imageUrlsToPreload.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = () => {
            console.log('Image preloaded:', imageUrl); // Print the URL of the preloaded image
            resolve();
          };
        });
      });

    };

    preloadImages();
  }, []);

  



// In the second useEffect, observe the refs:
useEffect(() => {
  if (categories.length === 0) return;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.filter = 'none';
        } else {
          entry.target.style.filter = 'grayscale(60%) sepia(40%)';
        }
      });
    },
    {
      threshold: 1,
    }
  );

  thumbnailRefs.current.forEach((ref) => {
    if (ref.current) {
      observer.observe(ref.current);
    }
  });

  return () => {
    thumbnailRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    });
  };
}, [categories]);

useEffect(() => {
  const imagesLoadedInterval = setInterval(() => {
    const images = document.querySelectorAll('.categories-grid div img');
    if (images.length) {
      clearInterval(imagesLoadedInterval);

      // images.forEach((image) => {
      //   console.log('Event listener attached to', image.alt);  // Debugging line
      //   // image.addEventListener('click', (event) => handleCategoryClick(event, image.alt));
      //   if (isIOS()) {
      //     image.addEventListener('touchend', (event) => handleCategoryClick(event, image.alt));
      // } else {
      //     image.addEventListener('click', (event) => handleCategoryClick(event, image.alt));
      // }
      // });
    }
  }, 500);

  return () => {
    clearInterval(imagesLoadedInterval);
    const images = document.querySelectorAll('.categories-grid div img');
    images.forEach((image) => {
      image.removeEventListener('click', handleCategoryClick);
    });
  };
}, []);


useEffect(() => {
  const goFullscreen = () => {
    const sliderContainer = document.querySelector('.slider-popup');
    
        if (sliderContainer && sliderContainer.requestFullscreen) {
          sliderContainer.requestFullscreen();
        } else if (sliderContainer && sliderContainer.mozRequestFullScreen) { /* Firefox */
            sliderContainer.mozRequestFullScreen();
        } else if (sliderContainer && sliderContainer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            sliderContainer.webkitRequestFullscreen();
        } else if (sliderContainer && sliderContainer.msRequestFullscreen) { /* IE/Edge */
            sliderContainer.msRequestFullscreen();
        } else {
          console.log('Full-screen not supported.');
        }

        // Attempt to lock the screen orientation to landscape
        if (window.screen.orientation && window.screen.orientation.lock) {
          window.screen.orientation.lock('landscape').catch((err) => {
            console.warn('Screen orientation lock failed:', err);
          });
        }
    // }
  };

  if (sliderOpen) {
    goFullscreen();
  }

  return () => {
    // Add logic to revert any iOS-specific styles you applied
    if (isIOS()) {
      const sliderContainer = document.querySelector('.slider-popup');
      if (sliderContainer) { // check for null
        sliderContainer.removeAttribute("style");
      }
    }
    // Clean up when the component unmounts
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    if (window.screen.orientation && window.screen.orientation.unlock) {
      window.screen.orientation.unlock();
    }
  };
}, [sliderOpen]);


const handleCategoryClick = (event, categoryName) => {
  console.log('handleCategoryClick fired: ', categoryName);
  if (isIOS() && event.type !== 'touchend') return;
   const apiUrl = process.env.NODE_ENV === 'production' ? 'https://polls-service-6qrvcljmeq-uc.a.run.app' : 'http://localhost:8080';


  setActiveCategory(categoryName);
  setSliderOpen(true);
  
  // Fetch images for this category from the backend
  axios.get(`${apiUrl}/api/imggallery/${categoryName}/`).then(response => {
    setActiveImages(response.data);
  });
};

const [touchStart, setTouchStart] = useState({ x: null, y: null });

const handleTouchStart = (event) => {
  setTouchStart({
    x: event.touches[0].clientX,
    y: event.touches[0].clientY,
  });
};

const handleTouchEnd = (event, name) => {
  const touchEnd = {
    x: event.changedTouches[0].clientX,
    y: event.changedTouches[0].clientY,
  };

  if (
    Math.abs(touchStart.x - touchEnd.x) < 5 &&
    Math.abs(touchStart.y - touchEnd.y) < 5
  ) {
    handleCategoryClick(event, name);
  }
};
  
  const handleCloseSlider = () => {
    setSliderOpen(false);
    setActiveCategory(null);
    setActiveImages([]);
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 4000,
    afterChange: (currentSlide) => {
      setActiveIndex(currentSlide);
    },
  };

  return (
    <div className="gallery-container" id="gallery-photos">
      <img src={SeparatorLine} className="separator" alt="Separator Line" />
      <h3>הסוויטות</h3>
      <div className="categories-grid">
        {categories.map((category, index) => (
          <div
            key={category.id}
            className="category-thumbnail"
            role="button"
            tabIndex="0"
            style={{ cursor: 'pointer' }}
            // onClick={() => handleCategoryClick(category.name)}
            onClick={(event) => handleCategoryClick(event, category.name)}
            onTouchStart={isIOS ? handleTouchStart  : undefined}
            onTouchEnd={isIOS ? (event) => handleTouchEnd(event, category.name) : undefined}
            // onTouchEnd={(event) => handleCategoryClick(event, category.name)} // For iOS touchend
          >
            <img 
              src={category.thumbnail} 
              alt={category.name} 
              ref={thumbnailRefs.current[index]} // Attach the ref
              />           
            <p>{category.name}</p>
          </div>
        ))}
      </div>
      {sliderOpen && (
        <div className="slider-popup" id="slider-popup">
          <button className="close-button" onClick={handleCloseSlider}>X</button>
          <Slider {...settings} >
            {activeImages.map((image, index) => (
              <div key={image.id}>
                <div className="slide-content">
                  <div className="image-container"> {/* Add this wrapper */}
                    {/* Remove the loading attribute for the first and last images */}
                    <img
                      src={image.image}
                      alt={image.title}
                      loading={index === 0 || index === 1 || index === activeImages.length - 1 ? '' : 'lazy'}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>

        </div>
      )}
    </div>
  );
};

export default Gallery;
