import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const CustomLink = ({ to, section, children, onClick, style }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHamakomSuitesPage = location.pathname === '/';

  const handleLinkClick = (e) => {
    e.preventDefault();
    navigate(to, { state: { scrollTo: section } });
    // If onClick prop is provided, call it.
    if (onClick) {
      onClick();
    }
  };

  if (isHamakomSuitesPage) {
    return (
      <ScrollLink to={section} smooth={true} duration={500} onClick={onClick} style={style}>
        {children}
      </ScrollLink>
    );
  }

  return (
    <a href="#" onClick={handleLinkClick} style={style}>
      {children}
    </a>
  );
};


export default CustomLink;
