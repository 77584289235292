import React, { useState, useEffect,useRef  } from "react";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SuiteType.css';
import { Link } from 'react-router-dom';


const RoomCard = ({ setBgColor }) => {
  const roomTypes = [
    {
      id: 1,
      image: 'https://storage.googleapis.com/djangobucket2023/photos/suite/711A8132.jpeg', // Replace with the actual image filename or path
      name: 'הסוויטות',
      description: 'סוויטה מפוארת ענקית עם בריכה לזוג או משפחה',
      urlis:'/LuxurySuites',
    },
    {
      id: 2,
      image: 'https://storage.googleapis.com/djangobucket2023/photos/vip/711A8524.jpeg', // Replace with the actual image filename or path
      name: 'סוויטת VIP',
      description: 'סוויטת VIP מפוארת ומפנקת במיוחד',
      urlis:'/LuxuryVips',
    },
    {
      id: 3,
      image: 'https://storage.googleapis.com/djangobucket2023/photos/do-family/711A8063.jpeg', // Replace with the actual image filename or path
      name: 'סוויטה דו משפחתית',
      description: 'סוויטה ענקית עם 2 חדרי שינה זוגיים, 2 גלריות לינה לילדים, בריכה וג\'קוזי ספא',
      urlis:'/LuxuryDomi',
    },
    {
      id: 4,
      image: 'https://storage.googleapis.com/djangobucket2023/photos/ahuza/711A8580.jpeg', // Replace with the actual image filename or path
      name: 'אחוזת הקשתות',
      description: 'וילת אירות יוקרתית עם 5 חדרי סוויטות, 2 חדרי ילדים ו-3 גלריות לינה לילדים',
      urlis:'/LuxuryVilla',
    },
    // Add more room types as needed
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const roomCardRef = useRef(null);
  const sliderRef = useRef(null);
  const [prevIndex, setPrevIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: getCenterPadding(),
    speed: 600,
    className: "slider",
    beforeChange: (current, next) => setActiveIndex(next),
  };

  function getCenterPadding() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1400) {
      return "550px"; // Adjust this value for desktop
    } else if (screenWidth >= 1200) {
      return "450px"; // Adjust this value for tablets
    }else if (screenWidth >= 1000) {
      return "350px"; // Adjust this value for tablets
    }else if (screenWidth >= 768) {
      return "250px"; // Adjust this value for tablets
    } else if (screenWidth >= 600) {
      return "130px"; // Adjust this value for tablets
    } else if (screenWidth >= 500) {
      return "50px"; // Adjust this value for tablets
    } else {
      return "0px"; // No padding for mobile
    }
  }
  
  
  // function getSlidesToShow() {
  //   const screenWidth = window.innerWidth;
  //   if (screenWidth >= 1200) {
  //     return 1; // Adjust this value for desktop
  //   } else if (screenWidth >= 768) {
  //     return 1; // Adjust this value for tablets
  //   } else {
  //     return 1; // Display only one slide for mobile
  //   }
  // }
  useEffect(() => {
    const slideBackground = document.querySelector('.slider-background');
    slideBackground.style.backgroundImage = `url('${roomTypes[activeIndex].image}')`;
  }, [activeIndex, roomTypes]);

  useEffect(() => {
    const checkScrollPosition = () => {
      const roomCardPosition = roomCardRef.current.getBoundingClientRect().top;
      if (roomCardPosition <= 70) {
        setBgColor('#2d2d2d');
      } else {
        setBgColor('white');
      }
    };

    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);
  const handleClick = (id) => {
    if ((activeIndex < id || (id==1 && activeIndex==3)) && (!(id==4 && activeIndex==0)) ) {
        sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
    //console.log(id, 'is id');
    //console.log(activeIndex, 'is  active id');
  };
  return (
    <div className="room-card-container" id="room-type" ref={roomCardRef}>
      <div className="slider-background"></div>
      <Slider ref={sliderRef} {...settings}>
        {roomTypes.map((room) => (
          <div className="room-card" key={room.id} onClick={() => handleClick(room.id)}>
            <div className="room-details">
              <h2 className="room-name">{room.name}</h2>
            </div>
            <div className="img-container">
              <img src={room.image} alt={room.name} className="room-image" />
            </div>
            <div className="room-details">
              <p className="room-description">{room.description}</p>
              <div className="more-details">
                <button className="more-details-button"><Link to={room.urlis}   
                onClick={() => {
                  window.scroll(0, 0);
                }}>פרטים נוספים</Link></button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RoomCard;