import React, { useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import './gpt.css';
import { Link } from 'react-router-dom';
import chatSend from '../images/chat-send.png'; 
import loader from '../images/loader.gif';



axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';


function ChatApp() {
  const [userInput, setUserInput] = useState('');
  const [messageList, setMessageList] = useState([]);
  const [loading, setLoading] = useState(false);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Scroll down to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messageList]); // Call this effect whenever the message list changes


  const sendMessage = async (event) => {
    event.preventDefault();

    const userMessage = `${userInput}`;
    setMessageList((prevMessages) => [...prevMessages, { role: "user", content: userInput }]);

        setLoading(true); // Set isLoading to true while waiting for response from API

    try {
        const session_id = sessionStorage.getItem("session_id");
        //console.log("session_id: ", session_id);
        let backendUrl;
        let csrfToken;
        if (process.env.NODE_ENV === 'development') {
            backendUrl = `http://localhost:8080/chatapp/api/chat/${session_id}/`;
        } else {
            backendUrl = `https://polls-service-6qrvcljmeq-uc.a.run.app/chatapp/api/chat/${session_id}/`;
        }
        
        
      const response = await axios.post(
        backendUrl,
        { message: userMessage },
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const aiMessage = `ChatGPT: ${response.data.message}`;
      setMessageList((prevMessages) => {
          const aiMessage = response.data.message.replace(
            /((https?):\/\/[^\s]+)/g,
            (match, url) => `<a href="${url}" target="_blank">${url}</a>`
          );
          return [
            ...prevMessages,
            { role: "assistant", content: aiMessage },
          ];
      });      
      setLoading(false);
      setUserInput(''); // Clears user input after receiving response from the API
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setLoading(false); // Set isLoading to false after receiving response from API
  };

  return (
    <div className="ChatApp">
      <div className="chat-container" >
            <div className="chat-messages" ref={chatContainerRef}>
            <h2>היי ברוכים הבאים למלון בוטיק המקום</h2>
            <h4>אשמח לענות לכל שאלה לגבי המלון , טיולים באיזור, מסעדות ועוד...</h4>
              {messageList.map((message, index) => (
                   <p
                     key={index}
                     className={message.role === "user" ? "user-message" : "chatgpt-message"}
                     dangerouslySetInnerHTML={{ __html: message.content }}
                   ></p>
              ))}
            </div>
              {loading && (
                <div className="loader">
                  <img src={loader} alt="Loading..." />
                </div>
              )}

        <form onSubmit={sendMessage}>
            <div className="send-container">
                <button style={{ 'backgroundImage': `url(${chatSend})` }} type="submit"></button>
                <textarea
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = e.target.scrollHeight + 'px';
                  }}
                   onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage(e);
                        }
                   }}
                  placeholder="מעניין אותי לדעת..."
                />
            </div>
          
        </form>
        {/*<Link to="/hamakom-suites">לינק לאתר</Link>*/}
        <p>powered by ChatGPT</p>
      </div>
    </div>
  );
}

export default ChatApp;
