import React, { useEffect, useState } from 'react';
// import './VipWelcome.css';

const Welcome = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./VipWelcome.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [zoom, setZoom] = useState(1); // state variable to handle zoom level

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Add event listeners on component mount and remove them on unmount
  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const deltaY = e.deltaY;
  //     if (deltaY < 0) {
  //       // Scrolling up
  //       setZoom((prevZoom) => Math.max(1, prevZoom - 0.00)); // Minimum zoom level of 1
  //     } else {
  //       // Scrolling down
  //       setZoom((prevZoom) => prevZoom + 0.00); // Increment zoom level
  //     }
  //   };

  //   window.addEventListener('wheel', handleScroll);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, []); // Empty dependency array so this effect runs once on mount and cleanup on unmount

  return (
    <section className="Luxwelcome"> 
        <div className="Luxwelcome-text">
           <h1 style={{ display: 'inline-block',marginLeft:'10px' }}> הסוויטה המלכותית VIP</h1> <p style={{ display: 'inline-block' }}>{'{170 מ"ר}'}</p>
           <p>הסוויטה המלכותית עוצבה באופן מיוחד בהשראת הארמונות המפוארים בהיסטוריה של הגליל, עם חלל פנימי ענק, נבנתה מאבן גלילית ,עץ וברזל מסותתים לכדי שלמות בטבע גלילי וממוקמת בקצה היער עם גינת דשא פסטורלית. הסוויטה המלכותית משתרעת על פני 170 מ"ר, יש לה לובי כניסה גדול עם סלון מרווח וספת שזלונג גדולה , עם מתחם בריכה פרטית סגורה ומחוממת, ג'קוזי ספא, גינה רחבה ועוד המון פינוקים מיוחדים לאורחי הVIP. </p>
        </div>
      <div className="Luxwelcome-overlay"></div>
    </section>
  );
};

export default Welcome;
