import React, { useEffect } from 'react';
import { FaBed, FaTree,FaAccusoft,FaSwimmingPool,FaDoorOpen,FaTv } from 'react-icons/fa';
import { RiEyeLine, RiHome2Line, RiWifiLine, RiArrowRightLine, RiUserLine, RiTimeLine } from 'react-icons/ri';
// import './FeaturesIcons.css';
import SeparatorLine from '../assets/photos/sep3.png';

const FeatureGrid = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./FeaturesIcons.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const features = [
    { name: 'מתאים לזוג עם עד 3 ילדים', icon: RiUserLine },
    { name: 'מיטה זוגית ענקית אשר עוצבה בידי אמני ברזל', icon: FaBed  },
    { name: 'כורסת עיסוי מפנקת (אמריקן קומפורט)', icon: FaAccusoft },
    { name: 'גודל הסוויטה: {150 מ\'} ', icon: RiHome2Line },
    { name: 'מתחם בריכה פרטית מחוממת עם שחייה נגד הזרם', icon: FaSwimmingPool },
    { name: 'חדר ילדים - עד 3 ילדים ', icon: FaDoorOpen },
    { name: 'טלוויזיה חכמה גדולה', icon: FaTv },
    { name: 'אינטרנט מהיר', icon: RiWifiLine },
    { name: 'מרפסת גדולה וגינה ענקית', icon: FaTree },
    { name: 'שעת כניסה: 14:30 , שעת יציאה 11:00 , ובשבת: שעת כניסה 15:00, שעת יציאה 12:00', icon: RiTimeLine },
  ];

  return (
    <div className="feature-container">
      <img src={SeparatorLine} className="separator" alt="Separator Line" />
      <h3>בכל סוויטה</h3>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <div className="feature" key={index}>
            <div className="icon-text">
              <div className="icon">
                {React.createElement(feature.icon)}
              </div>
              <div className="name">{feature.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ); 
};

export default FeatureGrid;