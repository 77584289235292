import './footer.css';
import { Link  } from 'react-router-dom';
import logo_nav_page from '../assets/photos/logo-missive-green-final.png'; 
import CustomLink from './CustomLink';

const Footer = () => {
  const combinedClickHandler = () => {
    window.scroll(0, 0);
  };
  return (
    <div className="footer-container">
      <Link 
          to={'/'} 
          onClick={() => {
              window.scroll(0, 0);
              combinedClickHandler();
          }}
          >
          <img className="logo-nav-page" src={logo_nav_page} alt="Logo" />
      </Link>
      <br/>
      <br/>
      <br/>
      {/* <div className="nav-links">
                <CustomLink to="/" section="gallery-photos"  >תמונות</CustomLink>
                <Link to={'/LuxurySuites'} onClick={combinedClickHandler} >סוויטת המקום</Link>
                <CustomLink to="/" section="room-type"  >הסוויטות</CustomLink>
                <Link to={'/LuxuryDomi'} onClick={combinedClickHandler} >סוויטה דו משפחתית</Link>
                <Link to={'/VirtualTour'} onClick={combinedClickHandler} >סיור וירטואלי</Link>
                <Link to={'/LuxuryVips'} onClick={combinedClickHandler} >הסוויטה המלכותית</Link>
                <Link to={'/'} onClick={combinedClickHandler} >דף הבית</Link>
                <Link to={'/LuxuryVilla'} onClick={combinedClickHandler} > אחוזת הקשתות<br />וילת המקום</Link>
      </div> */}
      <div className="small-links">

                <CustomLink to="/" section="gallery-photos"  >תמונות</CustomLink>
                <Link to={'/LuxurySuites'} onClick={combinedClickHandler} >סוויטת המקום</Link>
                <CustomLink to="/" section="room-type"  >הסוויטות</CustomLink>
                <Link to={'/LuxuryDomi'} onClick={combinedClickHandler} >סוויטה דו משפחתית</Link>
                <Link to={'/VirtualTour'} onClick={combinedClickHandler} >סיור וירטואלי</Link>
                <Link to={'/LuxuryVips'} onClick={combinedClickHandler} >הסוויטה המלכותית</Link>
                <Link to={'/'} onClick={combinedClickHandler} >דף הבית</Link>
                <Link to={'/LuxuryVilla'} onClick={combinedClickHandler} > אחוזת הקשתות<br />וילת המקום</Link>
                <Link to={'/negishot'} onClick={combinedClickHandler} >תקנון</Link>
                <Link to={'/negishot'} onClick={combinedClickHandler} >נגישות</Link>


      </div>
            
      <a className="phone" href="tel:123 456 7890"><span>📞04-9806-006</span></a>
      <div>מלון בוטיק המקום, מושב נטועה, גליל מערבי </div>
    </div>
  );
};

export default Footer;
