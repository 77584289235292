import React, { useEffect, useState } from 'react';
// import './DomiWelcome.css';

const Welcome = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./DomiWelcome.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [zoom, setZoom] = useState(1); // state variable to handle zoom level

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Add event listeners on component mount and remove them on unmount
  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const deltaY = e.deltaY;
  //     if (deltaY < 0) {
  //       // Scrolling up
  //       setZoom((prevZoom) => Math.max(1, prevZoom - 0.00)); // Minimum zoom level of 1
  //     } else {
  //       // Scrolling down
  //       setZoom((prevZoom) => prevZoom + 0.00); // Increment zoom level
  //     }
  //   };

  //   window.addEventListener('wheel', handleScroll);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, []); // Empty dependency array so this effect runs once on mount and cleanup on unmount

  return (
    <section className="Luxwelcome"> 
        <div className="Luxwelcome-text">
           <h1 style={{ display: 'inline-block',marginLeft:'10px' }}> סוויטה דו משפחתית</h1> <p style={{ display: 'inline-block' }}>{'{200 מ"ר}'}</p>
           <p>הסוויטה הדו משפחתית היא וילה מהממת לשתי משפחות או שני זוגות , וחוויה מדהימה לילדים במתחם, בבריכה ובגינה הגדולה. הסוויטה עוצבה ונבנתה מאבן גלילית ,עץ וברזל מסותתים לכדי שלמות בטבע גלילי בין העצים והנחלים של הגליל המערבי, הסוויטה משתרעת על פני 200 מ"ר! (בנוי) עם מתחם בריכה מחוממת מקורה עם אפשרות לשחייה נגד הזרם וג'קוזי ספא. בסוויטה שני שולחנות אוכל גדולים בלובי כניסה ובמרפסת עם יציאה לגינה פרטית גדולה.</p>
        </div>
      <div className="Luxwelcome-overlay"></div>
    </section>
  );
};

export default Welcome;
