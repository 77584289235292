
import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaArrowUp } from 'react-icons/fa';

import NavigationBar from './NavigationBar';
import FloatingButton from './FloatingButton';
import SuiteType from './SuiteType';


import './Pages.css';




const Negishot = () => {
  const [activeDiv, setActiveDiv] = useState(null);
  const [bgColor, setBgColor] = useState('white');

  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);
  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    
    <div className="Tos" id="Tos">
      <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <div className='termofservice'>
          <p dir="RTL">
          תנאי האתר
          </p>
          <p className="tosh3" dir="RTL">
              1. כללי
          </p>
          <p dir="RTL">
              1.1. אתר  האינטרנט הנו אתר האינטרנט הרשמי של מלון בוטיק המקום (להלן:
              "המלון")
          </p>
          <p dir="RTL">
              1.2. כל  המשתמש באתר זה, מצהיר כי קרא את תוכן תקנון זה, וכי הוא מסכים בצורה
              חד משמעית  לכל הוראותיו ותנאיו, וכי לא תהיה לו ו/או למי מטעמו, כל טענה ו/או
              דרישה ו/או  תביעה, במישרין ו/או בעקיפין, כנגד האתר ו/או מי מבעליו ו/או מי
              ממנהליו ו/או מי  מטעמו ו/או המלון.
          </p>
          <p dir="RTL">
              1.3 חלק מתוכן אתר זה מנוסח בלשון זכר מטעמי נוחות בלבד, אך מיועד לנשים
              וגברים כאחד.
          </p>
          <p dir="RTL">
              1.4  המידע המוצג באתר ו/או כל חלק ממנו הינו רכושו הבלעדי של המלון, ולמלון
              שמורות זכויות היוצרים במידע ו/או כל חלק ממנו, המשתמש מצהיר כי הוא מתחייב
              להשתמש  במידע באתר לשימושו האישי בלבד, ולא להעתיק, לצלם, לשכפל, לשדר, להדפיס
              או לפרסם  את המידע באתר, בין אם בבעלות המלון ובין אם בבעלות צדדים שלישיים,
              ו/או חלק ממנו  לכל אדם ו/או גוף כלשהו ללא הסכמה מראש ובכתב של המלון, למעט
              לצורך אישי בלתי  מסחרי במידע.
          </p>
          <p dir="RTL">
              1.5. המשתמש  מתחייב שלא לעשות במידע כל סילוף או שינוי אחר, או כל פעולה
              העלולה לפגוע בנכונות  המידע ובמהימנותו או בכבודו או בשמו של המלון כבעל
              זכויות היוצרים בו, או של כל  גורם אחר.
          </p>
          <p className="tosh3" dir="RTL">
              2. אחריות
          </p>
          <p dir="RTL">
              2.1 המשתמש מצהיר בזאת, כי הוא אחראי באופן בלעדי ומלא לכל שימוש שהוא  עושה
              ו/או יעשה במידע באתר.
          </p>
          <p dir="RTL">
              2.2. המידע  הינו לשימושו האישי של המשתמש בלבד והשימוש בו, לרבות כל הסתמכות
              עליו, הינם על  אחריותו הבלעדית של המשתמש. בין היתר, מצהיר המשתמש כי הוא מודע
              לכך שהתמונות  המוצגות באתר הינן לצורך המחשה בלבד, וכי ייתכנו שינויים בין
              הצגתן על גבי מסך  המחשב לבין מראייהן במציאות.
          </p>
          <p dir="RTL">
              2.3 המידע מוצג באתר כפי שהוא והמלון אינו  אחראי להתאמת המידע לצרכי המשתמש
              ואינו אחראי כלפי המשתמש בשל אי יכולתו לעשות  שימוש במידע מסיבה כלשהי. המשתמש
              לבדו יישא באחריות לאופן בו הנו עושה שימוש במידע.
          </p>
          <p dir="RTL">
              2.4 המלון עושה הכל כדי שהמידע יהיה נכון ומדויק ככל שניתן, אולם המידע  אינו
              מתיימר להוות ניתוח שלם, מלא או מפורט של כלל הנושאים המפורטים בו. המידע  הינו
              כללי בלבד, חלקי ומבוסס. המלון לא יישא בכל אחריות לגבי טעות במידע המוצג
              באתר, הן על ידי המלון והן על ידי צדדים שלישיים.
          </p>
          <p dir="RTL">
              2.5 המלון לא יהיה אחראי בגין כל נזק, אי נוחות, הפסד, או הוצאה או עוגמת  נפש,
              העלולים להיגרם למשתמש או לרכושו (בין היתר לציוד החומרה ו/או התוכנה של
              המשתמש) או לכל צד שלישי, במישרין או בעקיפין, עקב הכניסה לאתר והשימוש בתכניו.
          </p>
          <p dir="RTL">
              2.6 המלון לא יישא באחריות לפעילות בלתי חוקית על ידי משתמש כל שהוא באתר  או
              כל גורם אחר שאינו בשליטתו.
          </p>
          <p dir="RTL">
              2.7 המלון לא יישא באחריות לשינויים שנעשו במידע על ידי המשתמש ו/או על  ידי כל
              צד שלישי אחר.
          </p>
          <p className="tosh3" dir="RTL">
              3. הזמנת  חופשה
          </p>
          <p dir="RTL">
              1.3. כללי
          </p>
          <p dir="RTL">
              3.1.1 המשתמש רשאי להשתמש באתר זה אך ורק על  מנת לבצע הזמנת ורכישת שירותים
              ו/או קבלת מידע. אין לעשות באתר זה כל שימוש למטרות  אחרות.
          </p>
          <p dir="RTL">
              3.1.2  הזמנת חדר במלון אפשרית באמצעות הזמנה באתר
              <a href="https://hamakom-suites.co.il%D7%90%D7%95/">
                  https://hamakom-suites.co.ilאו
              </a>
              בפנייה  טלפונית למלון בטלפון שמספרו 04-9806-006
          </p>
          <p dir="RTL">
              3.1.3  כל המזמין חדר באמצעות אתר זה, עשוי להדרש להזין את  פרטיו האישיים,
              לרבות מס' תעודת זהות או מס' דרכון ומס' כרטיס אשראי, וכן פרטים של  כל
              המתארחים עמו.
          </p>
          <p dir="RTL">
              3.1.4  כל ההזמנה שבוצעה דרך אתר האינטרנט מחייבת את  המזמין.
          </p>
          <p dir="RTL">
              3.2 פרטי רוכש השירותים
          </p>
          <p dir="RTL">
              3.2.1 בעת ביצוע רכישת השירותים באמצעות האתר  יידרש הרוכש להזין במערכת פרטים
              אישיים. בעלי האתר ו/או מי ממפעיליו ו/או מי  מבעליו ו/או מי מנהליו ו/או מי
              מטעמם אינם אחראים לטעות הנעשית על ידי הרוכש  בהקלדת פרטי הרכישה, לרבות טעות
              בבחירת חבילת הנופש, תאריך, מספר מיטות וכן כל  שירות אחר שיוזמן על ידי הרוכש
              דרך האתר. המלון לא ישא במישרין או בעקיפין באחריות  כלשהי למקרה שבו פרטי
              הרכישה לא יקלטו במערכת, ו/או לכל בעיה טכנית ו/או אחרת  המונעת מהרוכש לרכוש
              שירותים באמצעות האתר.
          </p>
          <p dir="RTL">
              3.2.2  המשתמש מצהיר כי הוא מודע לכך שעם ההזמנה הוא ייתווסף  כלקוח במאגר
              המידע של המלון. המלון יהא רשאי להשתמש בפרטי ההזמנה לצורך שיפור  השירותים
              שהוא מציע למשתמש באתר וכן ליצירת קשר עם המשתמש באמצעות דואר רגיל, דואר
              אלקטרוני וכן כל אמצעי תקשורת אחר והתאמת האתר לצרכיו והעדפותיו האישיות. <br/>
              <br/>
          </p>
          <p dir="RTL">
              3.2.3 המשתמש מעוניין/ת ומסכים/ה לקבל מהמלון  בכל אמצעי התקשורת והמדיה כולל
              במייל ובהודעת sms,  הודעות אלקטרוניות, הודעות מסר קצר, ניוזלטרים, דברי שיווק
              ופרסום בעלי אופי  מסחרי/פרסומי ו/או כל דבר פרסומת כהגדרתו בחוק התקשורת (בזק
              ושירותים), תשמ"ב  – 1982, בקשר למוצרים ושירותים, לינק להסרה מקבלת הודעות
              מתווסף להודעות ומאפשר  לבקש הסרה מרשימת התפוצה.
          </p>
          <p dir="RTL">
              3.3. אופן  ההזמנה
          </p>
          <p dir="RTL">
              3.3.1  המשתמש יזין את פרטי ההזמנה הנחוצים באתר.
          </p>
          <p dir="RTL">
              3.3.2  בעת הזנת פרטי ההזמנה, יידרש המשתמש להזין פרטי  כרטיס אשראי
          </p>
          <p dir="RTL">
              3.3.3  20%  מגובה ההזמנה ישולם במועד ההזמנה באתר, וההפרש יתבצע  בעת ההגעה
              למלון במזומן ו\או בכרטיס אשראי.
          </p>
          <p className="tosh3" dir="RTL">
              4. תנאים  והגבלות
          </p>
          <p dir="RTL">
              4.1 המחירים באתר הנם בשקלים וכוללים  מע"מ, בהתאם לשיעור המע"מ המשתנה מעת
              לעת.
          </p>
          <p dir="RTL">
              4.2 המלון רשאי לשנות בכל עת את תנאי הזמנת החדרים ואת מחירי החבילות.
          </p>
          <p dir="RTL">
              4.3 המלון רשאי לשנות את תקנון האתר ותנאי השימוש מעת לעת מבלי למסור על  כך
              הודעה מראש למשתמש. המלון יפרסם באתר את התנאים החדשים ותחילת תוקפם תהיה במועד
              פרסומם.
          </p>
          <p dir="RTL">
              4.4 המלון רשאי לסגור את האתר ולשנות מעת לעת  את מבנהו, תוכנו, מראהו, היקפם
              וזמינותם של השירותים והתכנים הניתנים בו, וכל היבט  אחר הכרוך באתר ותפעולו
              ללא צורך להודיע למשתמש על כך מראש. המשתמש מצהיר כי לא  תהא לו כל טענה ו/או
              תביעה ו/או דרישה כלפי המלון בקשר לכך.
          </p>
          <p dir="RTL">
              4.5 מספר החדרים במלון מוגבל וביצוע הזמנת חדר הנו על בסיס מקום פנוי  בלבד.
          </p>
          <p dir="RTL">
              4.6 אין כפל מבצעים והנחות.
          </p>
          <p dir="RTL">
              4.7. קבלת  ופינוי חדרים: <br/>
              <br/>
              שעת קבלת הסוויטה -14:30 שעת יציאה - 11:00.
          </p>
          <p dir="RTL">
              ביום שבת שעת כניסה - 15:00 שעת יציאה ביום שבת  וביום ראשון בשעה 12:00.
          </p>
          <p dir="RTL">
              4.8. תינוקות  וילדים:
          </p>
          <p dir="RTL">
              4.8.1  הגדרות:
          </p>
          <p dir="RTL">
              "תינוק" – מי שגילו עד שנתיים.
          </p>
          <p dir="RTL">
              " ילד" – מי שגילו עד 12 שנים.
          </p>
          <p dir="RTL">
              4.9 אירוח ילדים ונוער מתחת לגיל 18, יתאפשר בליווי מבוגר מעל גיל 21  בלבד.
          </p>
          <p dir="RTL">
              4.10  על הסכם זה יחולו אך ורק דיני מדינת ישראל.  מקום השיפוט הבלעדי לכל
              עניין הנוגע להסכם זה והשימוש באתר הינו בבתי המשפט  המוסמכים במחוז עכו.
          </p>
          <p dir="RTL">
              4.11  המשתמש מודע לכך שהשימוש באתר אינטרנט באתר מטבעו  עלולות להיות תקלות
              טכניות.
          </p>
          <p className="tosh3" dir="RTL">
              5. ביטול  הזמנה
          </p>
          <p dir="RTL">
              5.1 ניתן לבטל את ו/או לשנות את ההזמנה באותו  היום ללא עלות, ועד 14 ימים בטרם
              מועד ההזמנה לאירוח בעלות של ₪100. ביטול ו/או  שינוי הזמנה יתבצע רק באמצעות
              נציג שירות טלפוני וע''י קבלת אישור ביטול או שינוי למייל,  בכל מקרה אחר
              ,המזמין יחוייב בדמי ביטול 100% מערך ההזמנה.
          </p>
          <p dir="RTL">
              שובר מתנה לא ניתן לבטל ולא ניתן לקבל החזר  כספי, בנוסף לא ניתן לשדרג שובר
              לסופ''ש גם לא בתוספת תשלום.
          </p>
          <p dir="RTL">
              5.2 ל"מלון בוטיק המקום" שמורה הזכות לשנות את מדיניות ההזמנות  ו/או הביטולים
              בכל עת (כולל עונות שיא, חגים וחופשים). הודעה תימסר למבצע ההזמנה  בעת ביצועה.
          </p>
          <p className="tosh3" dir="RTL">
              6. הוראות שונות
          </p>
          <p dir="RTL">
              6.1 כניסת בעלי חיים אסורה למלון ולמתקניו השונים.
          </p>
          <p dir="RTL">
              6.2 אין להזמין אורחים נוספים מעבר לנקוב בהזמנה ללא קבלת הסכמת המלון  מראש.
              הבאת אורחים ללינה תחויב בתשלום נוסף.
          </p>
          <p dir="RTL">
              6.3 אין להוציא ציוד מחדרי האירוח.
          </p>
          <p dir="RTL">
              6.4 אין לעשן בחדרי הסוויטות ,באיזורי הבריכה, בחדר האוכל ובבנין המרכזי.
          </p>
          <p className="tosh3" dir="RTL">
              7 שמירה על המקום
          </p>
          <p dir="RTL">
              7.1 המשתמש מתחייב בשמו ובשם כל שאר המתארחים עמו לשמור על המקום ולא  לגרום כל
              נזק במזיד ו/או ברשלנות לאתר, לרבות כל הציוד שבו.
          </p>
          <p dir="RTL">
              7.2 המשתמש מאשר כי במקרה של נזק כלשהו, יחויב  כרטיס האשראי דלעיל במלוא עלות
              הנזק.
          </p>
      </div>
      <SuiteType setBgColor={setBgColor} />
      <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>
      <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

    </div>
           

  );
}

export default Negishot;