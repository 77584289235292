  import React, { useState, useEffect } from "react";
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css"; 
  import "slick-carousel/slick/slick-theme.css";
  // import './Intro2nd.css';

  const Intro2nd = () => {
    useEffect(() => {
      const importCss = async () => {
        try {
          await import('./Intro2nd.css');//replacing the css with the css of the gallery of the suites
          console.log("Component XYZ is mounted!");
        } catch (err) {}
      };
      importCss();
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);

    const settings = {
      dots: true,
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: getCenterPadding(),
      speed: 500,
      className: "slider",
      beforeChange: (current, next) => setActiveIndex(next),
    };
    function getCenterPadding() {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1200) {
        return "200px"; // Adjust this value for desktop
      } else if (screenWidth >= 768) {
        return "100px"; // Adjust this value for tablets
      } else {
        return "30px"; // No padding for mobile
      }
    }
    
    return (
      <div className="Intro2nd-container">
        <Slider {...settings}>
          <div className="feature">
            <div className="featu-img">
              <img src="https://storage.googleapis.com/djangobucket2023/photos/suite/711A8132.jpeg" alt="מרחב הסוויטות מלון בוטיק המקום"></img>
            </div>
            <h4>מרחב הסוויטות</h4>
            <p className={activeIndex === 0 ? "open" : "closed-paragraph"}>
            חלל האירוח המרכזי כולל פינה סלונית מהודרת, כורסת עיסוי מפנקת (מבית אמריקן קומפורט) מיטה זוגית
              ענקית אשר עוצבה בידי אמני ברזל, עם מזרוני קינג קויל אורטופדים. בחדר האירוח הנוסף: 2 כורסאות
              איכות של "עמינח" הנפתחות, האחת - למיטה זוגית והשנייה - למיטה בודדת. כל המצעים הינם מצעי יוקרה
              100% כותנת סאטן. פינת המטבח כוללת: מכונת אספרסו, תמי בר (מים חמים וקרים), מקרר ומיקרוגל ואבזור
              כולל של כלי אוכל וסכו"ם. במקרר, משקאות קלים לצד חלב טרי ובפינת הקפה: קפסולות למכונת האספרסו
              לצד קפה ותה לסוגיו. בנוסף, לכל אורח חלוק רחצה מפנק ונעלי בית רכות.
            </p>
          </div>
          <div className="feature">
            <div className="featu-img">
              <img src="https://storage.googleapis.com/djangobucket2023/photos/suite/711A8143.jpeg" alt="מרחב הסוויטות מלון בוטיק המקום"></img>
            </div>
            <h4>פינוק ושירות חסר גבולות</h4>
            <p className={activeIndex === 1 ? "open" : "closed-paragraph"}>
            מלון בוטיק "המקום" שואף לשלמות בעיצוב, יוקרה ופינוקים המשולבים בסטנדרטים הגבוהים ביותר למתן
              שירות אישי ויחס חם ועוטף לכל לקוח. כל אחת מהסוויטות כוללת בריכת שחיה פרטית מחוממת עם אפשרות
              לשחייה נגד הזרם, ג'קוזי ספא מקצועי, חלל אירוח מרכזי וחדר אירוח מרווח נוסף, מערכות קולנוע וסאונד
              מתקדמות, מסך פלאזמה 50'', ספריה, כורסאות עיסוי מבית אמריקן קומפורט, פינת אוכל, מכונת נספרסו
              ומטבחון, מסך פלזמה 32 בחדר האירוח הנוסף, כבלים, אינטרנט אלחוטי ותאורה חכמה. פרטיות ואינטימיות
              ניכרים בכל פינה ומתורגמים לנוחות, שקט ותחושה אינסופית של רוגע. כל פרט נבחר בקפידה ומשתבץ בהרמוניה
              מושלמת בחורש הטבעי ובנופו הירוק של הגליל. בחורף הסוויטות מחוממות באמצעות קמין גז איטלקי ובקיץ
              הסוויטות ממוזגות באמצעות מזגן מרכזי שקט במיוחד.
            </p>
          </div>
          <div className="feature">
            <div className="featu-img">
              <img src="https://storage.googleapis.com/djangobucket2023/photos/vip/711A8279.jpeg" alt="מרחב הסוויטות מלון בוטיק המקום"></img>
            </div>
            <h4>עיצוב ארכיטקטוני עדין ועוצמתי לנפש ולגוף</h4>
            <p className={activeIndex === 2 ? "open" : "closed-paragraph"}>
            הקונספט הייחודי של מלון בוטיק "המקום" מעצב כאמן את תחושת הנוחיות והשלווה ומרחיב את עוצמת
              הגוף והנפש. עיצוב המקום נשען על איכות חומרים והתאמה טבעית לסביבה הכפרית והגלילית. כל אחת מהסוויטות
              בנויה משילוב ייחודי של עץ, אבן גלילית ומתכת בהשראת האוטנטיות הגלילית. 13 הסוויטות נושאות שמות
              של ציפורי א"י ועוצבו בידי טובי האמנים בגליל (כך למשל על עבודות הברזל היו אמונים ראובן גפני ויובל
              תלם, שני אמנים גליליים ידועים בתחום אמנות הברזל).
            </p>
          </div>
        </Slider>
      </div>
    );
  };

  export default Intro2nd;
