import React, { useState, useEffect } from 'react';
import './VirtualTour.css';
//import './App.css';
import { FaArrowUp } from 'react-icons/fa';


// Components
//import Header from './components/Header';
import NavigationBar from './NavigationBar';
import FloatingButton from './FloatingButton';
import { animateScroll as scroll } from 'react-scroll';

const VirtualTour = () => {
  const [bgColor] = useState('white');
  const [activeDiv, setActiveDiv] = useState(null);
  //this is a temp patch for the observer loop annoying issue:
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    })
  }, [])
  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);

  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    
    <div className="App luxsuitesapp" id="lux1">
     
      <link rel="stylesheet" href="https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.css" />
      <link rel="stylesheet" href="https://bookingresults.com/2020.hamakom-suites/Content/new_be/SBoxCustomTheme.css" />

{/*       <Header scrollPosition={scrollPosition} /> */}
      <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <div className="container2">
        <div className="iframe-container">
          <iframe className="responsive-iframe" src="https://storage.net-fs.com/hosting/6830790/24/index.htm?media-index=9"></iframe>
        </div>
      </div>


        <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>

        <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

    </div>
  );
}

export default VirtualTour;