import React, { useEffect, useState } from 'react';
// import './VillaWelcome.css';

const Welcome = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./VillaWelcome.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [zoom, setZoom] = useState(1); // state variable to handle zoom level

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Add event listeners on component mount and remove them on unmount
  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const deltaY = e.deltaY;
  //     if (deltaY < 0) {
  //       // Scrolling up
  //       setZoom((prevZoom) => Math.max(1, prevZoom - 0.00)); // Minimum zoom level of 1
  //     } else {
  //       // Scrolling down
  //       setZoom((prevZoom) => prevZoom + 0.00); // Increment zoom level
  //     }
  //   };

  //   window.addEventListener('wheel', handleScroll);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, []); // Empty dependency array so this effect runs once on mount and cleanup on unmount

  return (
    <section className="villa-welcome"> 
        <div className="Luxwelcome-text">
           <h1 style={{ display: 'inline-block',marginLeft:'10px' }}> וילת המקום - אחוזת הקשתות</h1> <p style={{ display: 'inline-block' }}>{'{470  מ"ר}'}</p>
           <p>אחוזת הקשתות מתאימה לאירוח עד 24 מבוגרים וילדים, עם מרחב פנימי ענק, תקרות גבוהות, וזרימה מדהימה בין כל החלל ולמתחם הבריכה, כך שתוכלו להרגיש כולם ביחד וגם את הפרטיות לכל משפחה, האחוזה נבנתה מאבן גלילית ועץ מלא, מסותתים לכדי שלמות בטבע גלילי.</p>
        </div>
      <div className="Luxwelcome-overlay"></div>
      <div className="Luxwelcome-text">
           <p>הווילה מציגה עיצוב נהדר ומתקנים ייחודיים מפנקים ביותר כמו בריכה מחוממת ומקורה בצורת גיטרה ספרדית, ג'קוזי ספא, טניס שולחן, שולחן סנוקר, איזור מנגל, פינות ישיבה בחוץ, מרחב גינה גדול ועוד.</p>
        </div>
    </section>
  );
};

export default Welcome;
