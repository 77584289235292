import React, { useEffect, useState } from 'react';
// import './VipIntroduction.css';

const Introduction = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./VipIntroduction.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  return (
    <div className="introduction-container">
      <div className="introduction-content">
        <h1 className="introduction-header">
        עונג צרוף והשראה דרמטית בכל פינה
        </h1>
        
        <div style={{ position: 'relative', padding: '1em' }}>
          <span style={{ fontSize: '3em', position: 'absolute', right: '-5px', top: '22px' }}>&ldquo;</span>
          <p className="introduction-text" >כמו בארמון, הרגשתי כמו מלכה בסוויטה המלכותית הכל כל כך גדול והמרחבים בחדר נותנים הרגשה כל כך וואוו , וואוו  פשוט וואוו. ואיזה בריכה... חלום, יצאנו אחהצ לגינה ובמשך שעה פשוט שכבנו על הערסל הסתכלנו על ההר הירוק והקשבנו לציפורים, מה זה המקום המושלם הזה.  </p>
          <span style={{ fontSize: '3em', position: 'absolute', left: '10px', bottom: '-30px' }}>&rdquo;</span>
        </div>
        {/* <a href="#welcome" className="introduction-button">
          הזמנת סוויטה
        </a> */}
      </div>
    </div>
  );
};

export default Introduction;
