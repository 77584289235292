// import './LuxGallery.css';
import React, { useState, useEffect ,lazy, Suspense} from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick-theme.css";
import SeparatorLine from '../../assets/photos/sep3.png';
import { MdFullscreen } from 'react-icons/md';

const Gallery = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./LuxGallery.css');
      } catch (err) {}
    };
    importCss();
  }, []);
  
 
  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'production' ? 'https://polls-service-6qrvcljmeq-uc.a.run.app' : 'http://localhost:8080';
  
    // Fetch images for "category one" directly from the backend
    axios.get(`${apiUrl}/api/imggallery/סוויטות פאר עם בריכה פרטית/`).then(response => {
      setActiveImages(response.data);
    });

    return () => {

  }; 
  }, []);
      const [activeImages, setActiveImages] = useState([]);
      const [popupActive, setPopupActive] = useState(false); // Add this line
      
      // Use useEffect to preload images
 useEffect(() => {
  const imageUrlsToPreload = [
    'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8088.jpeg', 
    'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8109.jpeg',
    'https://storage.googleapis.com/djangobucket2023/gallery-images/711A8117.jpeg',
    

    
    // Add more image URLs as needed
  ];
  // Preload images
  const preloadImages = () => {
    const promises = imageUrlsToPreload.map((imageUrl) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          console.log('Image preloaded:', imageUrl); // Print the URL of the preloaded image
          resolve();
        };
      });
    });

  };

  preloadImages();
}, []);

      // Define the goFullscreen function
      const goFullscreen = () => {
        const sliderContainer = document.getElementById('slider-container');
        if (sliderContainer) {
  
          if (sliderContainer.requestFullscreen) {
              sliderContainer.requestFullscreen();
          } else if (sliderContainer.mozRequestFullScreen) { /* Firefox */
              sliderContainer.mozRequestFullScreen();
          } else if (sliderContainer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
              sliderContainer.webkitRequestFullscreen();
          } else if (sliderContainer.msRequestFullscreen) { /* IE/Edge */
              sliderContainer.msRequestFullscreen();
          } else {
            sliderContainer.requestFullscreen().catch((err) => {
            });
        }
        }

      // Attempt to lock the screen orientation to landscape
      if (window.screen.orientation && window.screen.orientation.lock) {
        window.screen.orientation.lock("landscape").catch((err) => {
        });
      }
    };

    // Define the exitFullscreen function
    const exitFullscreen = () => {
      if (document.exitFullscreen) {
          document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
      }

      if (window.screen.orientation && window.screen.orientation.unlock) {
        window.screen.orientation.unlock();
      }
    };
    const toggleFullscreen = () => {
      if (!document.fullscreenElement) {
          setPopupActive(!popupActive); // Toggle the class
          goFullscreen();   
      } 
    };
    const handleCloseSlider = () => {
      setPopupActive(false);
      exitFullscreen();
    };
  const [activeIndex, setActiveIndex] = useState(0);
 
  const sliderPopupDiv = document.getElementById('slider-container');

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1, // Show a little of the next slide
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 8000,
    centerMode: true,
    centerPadding: "50px",
    className: "slider",
    afterChange: (currentSlide) => {
      if (!sliderPopupDiv.classList.contains('active')) {
        toggleFullscreen();
      }
      setActiveIndex(currentSlide);
    },
    responsive: [
      {
        breakpoint: 768, // Screen width for mobile
        settings: {
          slidesToShow: 1, // Adjust this value as needed
        }
      }
    ]
  };

  return (
    <div className="Luxgallery-container" id="gallery-photos">
      <img src={SeparatorLine} className="separator" alt="Separator Line" />
      <h3>הסוויטות</h3>
      <div className={popupActive ? "slider-container active" : "slider-container"} id="slider-container">
      <button className="close-button" onClick={handleCloseSlider}>X</button>
      <Slider {...settings} >
        {activeImages.map((image, index) => (
          <div key={image.id}>
            <div className="slide-content">
              <div className="image-container"> {/* Add this wrapper */}
                {/* Remove the loading attribute for the first and last images */}
                <img
                  src={image.image}
                  alt={image.title}
                  loading={index === 0 || index === 1 || index === activeImages.length - 1 ? '' : 'lazy'}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
          
        
      </div>
    </div>
  );
};
export default Gallery;
