import React, { useEffect, useState, useRef } from 'react';

// import './FloatingButton.css';
import { MdClose } from 'react-icons/md';

const FloatingButton = ({ activeDiv, setActiveDiv }) => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./FloatingButton.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const [showBookingOverlay, setShowBookingOverlay] = useState(false);
  const sboxRef = useRef(null);

  const [showSearchBox, setShowSearchBox] = useState(false);

  return (
    <>

      <div className="butba23">
        <button className="floating-button" onClick={() => {setShowBookingOverlay(true);setActiveDiv('div1');}}>
          להזמנת חופשה
        </button>
        
      </div>

      <div className={`booking-overlay ${showBookingOverlay ? 'show' : ''}`} id="booking-overlay">
        <button className="close-button" onClick={() => {setShowBookingOverlay(false);}}><MdClose /></button>
        <h3> בחרו תאריכים לחופשה</h3>
        <div className='bo-wrap'>
          {activeDiv === 'div1' && <div ref={sboxRef} id="sbox" data-url="https://bookingresults.com/2020.hamakom-suites/actionUrl?lang=heb"></div>}
        </div>
        {/* Include booking form or other content here */}
      </div>
    </>
  );
};

export default FloatingButton;
