import React, { useState } from 'react';
import axios from 'axios';



axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

function GetEmbeddings() {
  const [embeddings, setEmbeddings] = useState([]);
  
  const handleGetEmbeddings = async () => {
    try {
            let backendUrl;
            let csrfToken;
            if (process.env.NODE_ENV === 'development') {
                backendUrl = `http://localhost:8080/chatapp/api/get-embeddings-model/`;
            } else {
                backendUrl = `https://polls-service-6qrvcljmeq-uc.a.run.app/chatapp/api/get-embeddings-model/`;
            }


          const response = await axios.post(
                backendUrl,
                {
                  text: "just checking something here"
                },
                {
                  headers: {
                    'X-CSRFToken': csrfToken,
                  }
                }
           );     
        const data = await response.data;
            
      setEmbeddings(data.embeddings);
      //console.log("embiding: ", data);

    } catch (error) {
      console.error('Error fetching embeddings:', error);
    }
  };

  return (
    <div>
      <button onClick={handleGetEmbeddings}>Get Embeddings</button>
      <div>
        {embeddings && embeddings.map((embedding, index) => (
          <p key={index}>{embedding}</p>
        ))}
      </div>
    </div>
  );
}

export default GetEmbeddings;
