import React from 'react';
import './NavigationPage.css';
import { GrClose } from 'react-icons/gr';
import { TbBeach } from 'react-icons/tb'; // import hotel icon
import CustomLink from './CustomLink';
import { Link  } from 'react-router-dom';
import logo_nav_page from '../assets/photos/logo-missive-green-final.png'; 


const NavigationPage = ({ closeNav }) => {
    const combinedClickHandler = () => {
        window.scroll(0, 0);
        closeNav();
      };
    return (
        <div className="nav-page">
            {/* Header section */}
            <div className="nav-header">
            <Link 
                to={'/'} 
                onClick={() => {
                    window.scroll(0, 0);
                    combinedClickHandler();
                }}
                >
                <img className="logo-nav-page" src={logo_nav_page} alt="Logo" />
            </Link>

                <div className="close-icon" onClick={closeNav}>
                        <GrClose size={30} />
                </div>
            </div>

            <div className="nav-links">
                <CustomLink to="/" section="gallery-photos"  onClick={closeNav}>תמונות</CustomLink>
                <Link to={'/LuxurySuites'} onClick={combinedClickHandler} >סוויטת המקום</Link>
                <CustomLink to="/" section="room-type" onClick={closeNav} >הסוויטות</CustomLink>
                <Link to={'/LuxuryDomi'} onClick={combinedClickHandler} >סוויטה דו משפחתית</Link>
                <Link to={'/VirtualTour'} onClick={combinedClickHandler} >סיור וירטואלי</Link>
                <Link to={'/LuxuryVips'} onClick={combinedClickHandler} >הסוויטה המלכותית</Link>
                <Link to={'/'} onClick={combinedClickHandler} >דף הבית</Link>
                <Link to={'/LuxuryVilla'} onClick={combinedClickHandler} > אחוזת הקשתות<br />וילת המקום</Link>
            </div>
            <div className="small-links">
                <Link to={'/negishot'} onClick={combinedClickHandler} >תקנון</Link>
                <Link to={'/negishot'} onClick={combinedClickHandler} >נגישות</Link>

                <a href="/negishot">תעודת כשרות</a>
                <a href="/Restaurants">מסעדות באיזור</a>
            </div>
            
            <a className="phone" href="tel:+97249806006"><span>📞04-9806-006</span></a>
            <div>מלון בוטיק המקום, מושב נטועה, גליל מערבי </div>

        </div>
    );
};

export default NavigationPage;
