import React, { useEffect, useState } from 'react';
import './Introduction.css';

const Introduction = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./Introduction.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  return (
    <div className="introduction-container">
      <div className="introduction-content">
        <h1 className="introduction-header">
          ברוכים הבאים למלון בוטיק המקום בגליל
        </h1>
        <p className="introduction-text">
          תשכחו כל מה שידעתם עד כה על חופשה בישראל, ותגייסו את הדמיון כי אנו עומדים לצאת למסע במקום קסום שאם עדיין לא הייתם בו, בוודאי לא תאמינו.
        </p>
        {/* <a href="#welcome" className="introduction-button">
          הזמנת סוויטה
        </a> */}
      </div>
    </div>
  );
};

export default Introduction;
