import React, { useEffect } from 'react';
import { FaBed, FaTree,FaAccusoft,FaSwimmingPool,FaDoorOpen,FaTv } from 'react-icons/fa';
import { RiEyeLine, RiHome2Line, RiWifiLine, RiVipLine, RiUserLine, RiTimeLine } from 'react-icons/ri';
// import './VillaFeaturesIcons.css';
import SeparatorLine from '../../assets/photos/sep3.png';

const FeatureGrid = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./VillaFeaturesIcons.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const features = [
    { name: 'מתאים לעד 24 אורחים', icon: RiUserLine },
    { name: '5 חדרי סוויטות מפוארים עם חדרי מקלחת צמודים גדולים, 4 חדרי ילדים, וחדר אורחים נוסף ', icon: FaDoorOpen },
    { name: 'לובי כניסה עם סלון', icon: RiVipLine  },
    { name: 'מיטה זוגית ענקית אשר עוצבה בידי אמני ברזל', icon: FaBed  },
    { name: 'כורסת עיסוי מפנקת (מבית אמריקן קומפורט)', icon: FaAccusoft },
    { name: 'גודל הסוויטה: {470 מ"ר} ', icon: RiHome2Line },
    { name: 'מתחם בריכה פרטית מחוממת עם שחייה נגד הזרם', icon: FaSwimmingPool },
    { name: 'טלוויזיה חכמה גדולה', icon: FaTv },
    { name: 'אינטרנט מהיר פתוח', icon: RiWifiLine },
    { name: 'מרפסת גדולה וגינה ענקית', icon: FaTree },
    { name: 'שעת כניסה: 14:30 , שעת יציאה 11:00 , ובשבת: שעת כניסה 15:00, שעת יציאה 12:00', icon: RiTimeLine },
  ];

  return (
    <div className="feature-container">
      <img src={SeparatorLine} className="separator" alt="Separator Line" />
      <h3>מה באחוזה</h3>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <div className="feature" key={index}>
            <div className="icon-text">
              <div className="icon">
                {React.createElement(feature.icon)}
              </div>
              <div className="name">{feature.name}</div>
            </div>
          </div>
        ))}
      </div>
      <div class="call-to-order-frame">
      <div class="frame-content">
        <h1>להזמנת אחוזת הקשתות התקשרו למחלקת הזמנות </h1>
        <p>לכל שאלה התקשרו אלינו למחלקת ההזמנות ונשמח לתכנן לכם חופשה מושלמת!🥰 לכל המשפחה וחברים באחוזת הקשתות המדהימה שלנו</p>
        <h2>04-9806-006</h2>
        <button onClick={() => window.location.href = 'tel:+97249806006'}>לחץ להתקשר</button>

      </div>
    </div>
    </div>
  ); 
};

export default FeatureGrid;
