
import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaArrowUp } from 'react-icons/fa';

import NavigationBar from './NavigationBar';
import FloatingButton from './FloatingButton';
import SuiteType from './SuiteType';

import './Pages.css';




const Restaurants = () => {
  const [activeDiv, setActiveDiv] = useState(null);
  const [bgColor, setBgColor] = useState('white');

  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);
  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    
    <div className="Restaurants" id="Restaurants">
      <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <div className='Restaurants-cont'>
        <div className="table-container">
        

          <p className="MsoTitle" dir="RTL" style={{textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><h2>רשימת מסעדות באיזור</h2></p>
          <p className="MsoBodyText" align="center" dir="RTL" style={{marginTop: '.05pt', marginRight: '141.05pt', marginBottom: '.15pt', marginLeft: '131.05pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{letterSpacing: '-.5pt'}}>*</span><span lang="HE" style={{letterSpacing: '-.5pt'}}>בסופי</span><span lang="HE" style={{letterSpacing: '-.55pt'}}> </span><span lang="HE" style={{letterSpacing: '-.5pt'}}>שבוע</span><span lang="HE" style={{letterSpacing: '-.6pt'}}> </span><span lang="HE" style={{letterSpacing: '-.5pt'}}>מומלץ</span><span lang="HE" style={{letterSpacing: '-.65pt'}}> </span><span lang="HE" style={{letterSpacing: '-.5pt'}}>להזמין</span><span lang="HE" style={{letterSpacing: '-.6pt'}}> </span><span lang="HE" style={{letterSpacing: '-.5pt'}}>מקומות</span><span lang="HE" style={{letterSpacing: '-.6pt'}}> </span><span lang="HE" style={{letterSpacing: '-.5pt'}}>מראש</span></p>
          <table className="responsive-table" border={1} cellSpacing={0} cellPadding={0} style={{marginLeft: '5.6pt', borderCollapse: 'collapse', border: 'none'}}>
            <tbody><tr style={{height: '13.75pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '15.4pt', marginBottom: '.0001pt', marginLeft: '16.15pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.1pt'}}>טלפון</span></b></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '33.95pt', marginBottom: '.0001pt', marginLeft: '34.75pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.1pt'}}>מיקום</span></b></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '12.55pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.25pt'}}>שם </span></b><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black'}}>המסעדה</span></b></p>
                </td>
                <td width={145} valign="top" style={{width: '108.85pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '9.1pt', marginBottom: '.0001pt', marginLeft: '9.7pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.25pt'}}>סוג</span></b><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.2pt'}}> </span></b><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black'}}>מסעדה</span></b></p>
                </td>
              </tr>
              <tr style={{height: '11.7pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" style={{marginTop: '.4pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.05pt', lineHeight: '10.3pt'}}>'<span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">ב</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.2pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.1pt'}}><span dir="LTR" />
                    </span>–<span style={{letterSpacing: '-.1pt'}}> </span>04-<span style={{letterSpacing: '-.1pt'}}>9574477</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '33.95pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>מעלות</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>תרשיחא</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '12.3pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>אלומה</span></p>
                </td>
                <td width={145} rowSpan={8} valign="top" style={{width: '108.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="left" style={{marginTop: '.45pt', textAlign: 'left'}}><b><span style={{fontSize: '14.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="right" dir="RTL" style={{marginTop: '.05pt', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '30.1pt', textAlign: 'left', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>מסעדת </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>שף</span></b></p>
                </td>
              </tr>
              <tr style={{height: '13.65pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '13.65pt'}}>
                  <p className="TableParagraph" style={{marginTop: '1.35pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.05pt'}}>'<span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">ב</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.2pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.1pt'}}><span dir="LTR" />
                    </span>–<span style={{letterSpacing: '-.1pt'}}> </span>04-<span style={{letterSpacing: '-.1pt'}}>9523707</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '13.65pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.9pt', marginRight: '33.85pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', lineHeight: '11.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>קיבוץ </span><span lang="HE" style={{fontSize: '12.0pt'}}>כברי</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '13.65pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.9pt', marginRight: '12.35pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', lineHeight: '11.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>אדלינה</span></p>
                </td>
              </tr>
              <tr style={{height: '11.7pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" style={{marginTop: '.4pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.05pt', lineHeight: '10.3pt'}}>'<span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">ד</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '2.1pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.1pt'}}><span dir="LTR" />
                    </span>04-<span style={{letterSpacing: '-.1pt'}}>9821250</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '33.85pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>מושב</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>לימן</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '12.45pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>ביסטרו</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>מיכאל</span></p>
                </td>
              </tr>
              <tr style={{height: '19.5pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '19.5pt'}}>
                  <p className="TableParagraph" style={{marginTop: '4.35pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.05pt'}}><span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.25pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.1pt'}}><span dir="LTR" />
                    </span>04-<span style={{letterSpacing: '-.1pt'}}>6921788</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '19.5pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.9pt', marginRight: '33.8pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>יער </span><span lang="HE" style={{fontSize: '12.0pt'}}>ביריה</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '19.5pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.9pt', marginRight: '12.45pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.35pt'}}>בת</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>יער</span></p>
                </td>
              </tr>
              <tr style={{height: '15.45pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" style={{marginTop: '2.2pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '15.95pt'}}>'<span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.4pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.2pt'}}><span dir="LTR" />
                    </span>053-938-<span style={{letterSpacing: '-.2pt'}}>8003</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.7pt', marginRight: '33.95pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>ג</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}><span dir="LTR" />'</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>יש</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>גוש<span style={{letterSpacing: '-.25pt'}}> </span>חלב</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.7pt', marginRight: '12.35pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>בית </span><span lang="HE" style={{fontSize: '12.0pt'}}>הציד</span></p>
                </td>
              </tr>
              <tr style={{height: '16.5pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '16.5pt'}}>
                  <p className="TableParagraph" style={{marginTop: '2.8pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.1pt'}}><span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.35pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.3pt'}}><span dir="LTR" />
                    </span><a href="https://www.google.com/search?q=%D7%A9%D7%90%D7%98%D7%95%2B%D7%93%D7%94%2B%D7%A8%D7%95%D7%90%D7%94&rlz=1C1SQJL_iwIL817IL817&oq=%D7%A9%D7%90%D7%98%D7%95&aqs=chrome.0.0i131i355i433i512j46i131i175i199i433i512j69i57j46i175i199i433i512j0i512j46i175i199i512l3j46i512j0i512.21046j0j15&sourceid=chrome&ie=UTF-8"><span style={{color: 'windowtext', textDecoration: 'none'}}>04-885-<span style={{letterSpacing: '-.2pt'}}>0088</span></span></a></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '16.5pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '2.3pt', marginRight: '33.85pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>מעיליא</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '16.5pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '2.3pt', marginRight: '12.45pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>שאטו</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>דה<span style={{letterSpacing: '-.15pt'}}> </span>רואה</span></p>
                </td>
              </tr>
              <tr style={{height: '15.3pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '15.3pt'}}>
                  <p className="TableParagraph" style={{marginTop: '2.2pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.1pt'}}><span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.35pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.3pt'}}><span dir="LTR" />
                    </span><a href="https://www.google.com/search?q=%D7%90%D7%9C%D7%A2%D7%9C%D7%99%2B%D7%92%D7%95%D7%A9%2B%D7%97%D7%9C%D7%91&rlz=1C1SQJL_iwIL817IL817&sxsrf=ALeKk03qjYE0cseDEfQexYO8vgCyGsz9BA%3A1620215356686&ei=PIaSYK_fI-G58gL395LYAg&oq=%D7%90%D7%9C%D7%A2%D7%9C%D7%99%2B%D7%92%D7%95%D7%A9%2B%D7%97%D7%9C%D7%91&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEM0CMgUIABDNAjoHCAAQRxCwAzoECCMQJzoECAAQDToICC4QsQMQgwE6CAgAELEDEIMBOgUIABCxAzoLCAAQsQMQxwEQrwE6CggAEMcBEK8BEEM6BAgAEEM6BwguELEDEEM6AgguOgUILhCTAjoCCAA6CggAELEDEIMBEAo6BwgAELEDEAo6BAgAEAo6BwgjEOoCECc6CwgAELEDEMcBEKMCOgQILhAnOgcILhBDEJMCOggIABDHARCvAToFCC4QsQM6CAgAEBYQChAeOgYIABANEB46BggAEBYQHlDYowFYya5sYIC3bGgYcAJ4AIABlwKIAfQakgEGMC4xNi40mAEAoAEBqgEHZ3dzLXdperABCsgBCMABAQ&sclient=gws-wiz&ved=0ahUKEwiv7afGvLLwAhXhnFwKHfe7BCsQ4dUDCA4&uact=5"><span style={{color: 'windowtext', textDecoration: 'none'}}>04-698-<span style={{letterSpacing: '-.2pt'}}>7819</span></span></a></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.3pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.7pt', marginRight: '33.95pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>ג</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}><span dir="LTR" />'</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>יש</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>גוש<span style={{letterSpacing: '-.25pt'}}> </span>חלב</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.3pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.7pt', marginRight: '12.45pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>אלעלי</span></p>
                </td>
              </tr>
              <tr style={{height: '15.45pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" style={{marginTop: '2.35pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '15.95pt'}}>'<span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">ג</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.2pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.15pt'}}><span dir="LTR" />
                    </span><span style={{letterSpacing: '-.1pt'}}>0525813445</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '33.95pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>מעלות</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>תרשיחא</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" style={{marginTop: '1.85pt', marginRight: '12.7pt', marginBottom: '.0001pt', marginLeft: '13.15pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>Koronfol</span></p>
                </td>
              </tr>
              <tr style={{height: '15.45pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" style={{marginTop: '2.35pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '15.95pt'}}><span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.4pt'}}><span dir="LTR" /> </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.2pt'}}><span dir="LTR" />
                    </span><a href="https://www.google.com/search?sa=X&rlz=1C1SQJL_iwIL817IL817&tbs=lf%3A1%2Clf_ui%3A9&tbm=lcl&sxsrf=AOaemvKb8KmEWpRbj1ap67Hfzuic0SvqAg%3A1631612108945&q=%D7%9E%D7%A1%D7%A2%D7%93%D7%94%2B%D7%A4%D7%A1%D7%95%D7%98%D7%94&rflfq=1&num=10&ved=2ahUKEwjrnKjhlP7yAhVO_rsIHU1rBKAQjGp6BAgPEEg&biw=1920&bih=937"><span style={{color: 'windowtext', textDecoration: 'none'}}>077-212-<span style={{letterSpacing: '-.2pt'}}>0234</span></span></a></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '33.8pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>פסוטה</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" style={{marginTop: '1.85pt', marginRight: '12.75pt', marginBottom: '.0001pt', marginLeft: '13.15pt'}}><span style={{fontSize: '12.0pt', color: '#1F2023'}}>Sharif<span style={{letterSpacing: '-.1pt'}}> Arlecchino</span></span></p>
                </td>
                <td width={145} rowSpan={2} valign="top" style={{width: '108.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" align="right" dir="RTL" style={{marginTop: '9.75pt', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.3in', textAlign: 'left', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>מסעדת</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.05pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>בשרים</span></b></p>
                </td>
              </tr>
              <tr style={{height: '15.45pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" style={{marginTop: '1.2pt', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.1pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>'</span><span lang="HE" dir="RTL">ש</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.2pt'}}><span dir="LTR" />
                    </span><span lang="HE" dir="RTL">פעיל</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.15pt'}}><span dir="LTR" /> </span>054-<span style={{letterSpacing: '-.1pt'}}>6614030</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '33.85pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>קיבוץ</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>אילון</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '12.45pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>ביסטרו</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '.1pt'}}> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />899</span></p>
                </td>
              </tr>
              <tr style={{height: '15.45pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '2.35pt', marginRight: '15.4pt', marginBottom: '.0001pt', marginLeft: '16.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{letterSpacing: '-.2pt'}}>תאום</span><span lang="HE" style={{letterSpacing: '-.25pt'}}> </span><span lang="HE">מראש<span style={{letterSpacing: '-.1pt'}}> </span></span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />-</span><span dir="RTL" /><span style={{letterSpacing: '-.35pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />0544450849</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '33.95pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>ישוב </span><span lang="HE" style={{fontSize: '12.0pt'}}>מתת</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '12.3pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', color: '#1F2023', letterSpacing: '-.2pt'}}>הבית</span><span lang="HE" style={{fontSize: '12.0pt', color: '#1F2023', letterSpacing: '-.35pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt', color: '#1F2023'}}>במתת</span></p>
                </td>
                <td width={145} valign="top" style={{width: '108.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '15.45pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.85pt', marginRight: '9.1pt', marginBottom: '.0001pt', marginLeft: '9.95pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.2pt'}}>ארוחת</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.15pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>שף<span style={{letterSpacing: '-.2pt'}}> </span>קולינרית</span></b></p>
                </td>
              </tr>
              <tr style={{height: '21.7pt'}}>
                <td width={201} valign="top" style={{width: '150.4pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '21.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '15.45pt', marginBottom: '.0001pt', marginLeft: '16.15pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{letterSpacing: '-.2pt'}}>פעיל </span><span lang="HE">בימי<span style={{letterSpacing: '-.25pt'}}> </span>א</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />-'</span><span lang="HE">ו</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />'</span><span dir="RTL" /><span style={{letterSpacing: '-.25pt'}}><span dir="RTL" /> </span><span lang="HE">בשעות<span style={{letterSpacing: '-.3pt'}}> </span></span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />08:00-</span></p>
                  <p className="TableParagraph" style={{marginTop: '.1pt', marginRight: '15.3pt', marginBottom: '.0001pt', marginLeft: '16.15pt', lineHeight: '9.85pt'}}><span style={{letterSpacing: '-.1pt'}}>16:00</span></p>
                </td>
                <td width={185} valign="top" style={{width: '138.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '21.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '4.85pt', marginRight: '33.8pt', marginBottom: '.0001pt', marginLeft: '34.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>מושב</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>גורן</span></p>
                </td>
                <td width={150} valign="top" style={{width: '112.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '21.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '4.85pt', marginRight: '12.35pt', marginBottom: '.0001pt', marginLeft: '13.15pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', color: '#1F2023', letterSpacing: '-.2pt'}}>מילר</span></p>
                </td>
                <td width={145} valign="top" style={{width: '108.85pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '21.7pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '4.85pt', marginRight: '9.1pt', marginBottom: '.0001pt', marginLeft: '9.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.25pt'}}>קפה</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.05pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>פטיסרי</span></b></p>
                </td>
              </tr>
            </tbody></table>
          <p className="MsoNormal" align="center" dir="RTL" style={{marginTop: '9.3pt', marginRight: '141.05pt', marginBottom: '1.1pt', marginLeft: '131.35pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', letterSpacing: '-.1pt'}}>מסעדות</span></b><b><span lang="HE" style={{fontSize: '14.0pt', letterSpacing: '-.2pt'}}> </span></b><b><span lang="HE" style={{fontSize: '14.0pt'}}>כשרות</span></b></p>
          <table className="responsive-table" border={1} cellSpacing={0} cellPadding={0} style={{marginLeft: '5.6pt', borderCollapse: 'collapse', border: 'none'}}>
            <tbody><tr style={{height: '13.75pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.1pt'}}>טלפון</span></b></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '39.45pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.1pt'}}>מיקום</span></b></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.3pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.25pt'}}>שם </span></b><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black'}}>המסעדה</span></b></p>
                </td>
                <td width={122} valign="top" style={{width: '91.8pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '11.7pt', marginBottom: '.0001pt', marginLeft: '12.25pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.25pt'}}>סוג</span></b><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.2pt'}}> </span></b><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black'}}>מסעדה</span></b></p>
                </td>
              </tr>
              <tr style={{height: '17.55pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '17.55pt'}}>
                  <p className="TableParagraph" style={{marginTop: '3.05pt', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>04-6016849</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '17.55pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.05pt', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '.55in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>ראש</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.35pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>הנקרה</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '17.55pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.05pt', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>הצוק</span></p>
                </td>
                <td width={122} rowSpan={2} valign="top" style={{width: '91.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.5pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '17.55pt'}}>
                  <p className="TableParagraph" align="left" style={{marginTop: '.35pt', textAlign: 'left'}}><b><span style={{fontSize: '11.5pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="right" dir="RTL" style={{marginLeft: '21.55pt', textAlign: 'left', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>מסעדת </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>שף</span></b></p>
                </td>
              </tr>
              <tr style={{height: '16.25pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'solid black 1.5pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '16.25pt'}}>
                  <p className="TableParagraph" style={{marginTop: '1.95pt', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>050-8868572</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.5pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '16.25pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.95pt', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '39.65pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>אגם</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.4pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>המונפורט</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.5pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '16.25pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '1.95pt', marginRight: '23.65pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>הדרלה</span></p>
                </td>
              </tr>
              <tr style={{height: '18.05pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" style={{marginTop: '3.15pt', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>04-9807658</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.15pt', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '.55in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>מושב</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>יערה</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.15pt', marginRight: '23.7pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>ביפ </span><span lang="HE" style={{fontSize: '12.0pt'}}>בהר</span></p>
                </td>
                <td width={122} rowSpan={3} valign="top" style={{width: '91.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="left" style={{marginTop: '.4pt', textAlign: 'left'}}><b><span style={{fontSize: '12.5pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" align="right" dir="RTL" style={{marginLeft: '11.35pt', textAlign: 'left', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>מסעדות</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.05pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>בשרים</span></b></p>
                </td>
              </tr>
              <tr style={{height: '18.05pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" style={{marginTop: '3.15pt', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>053-9439547</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.15pt', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '39.35pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>בצת</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '18.05pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '3.15pt', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>300</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>גרם</span></p>
                </td>
              </tr>
              <tr style={{height: '11.85pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '11.85pt'}}>
                  <p className="TableParagraph" style={{marginTop: '.05pt', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt', lineHeight: '10.8pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>04-9524333</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.85pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.05pt', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '39.5pt', textAlign: 'center', lineHeight: '10.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>אכזיב</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.85pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.05pt', marginRight: '23.7pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', lineHeight: '10.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>מורגנפלד</span></p>
                </td>
              </tr>
              <tr style={{height: '23.6pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '4.6pt', marginBottom: '.0001pt', marginLeft: '5.4pt', textAlign: 'center', lineHeight: '11.7pt', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>049858128</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>משלוחים</span></p>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt', lineHeight: '10.9pt'}}><span style={{fontSize: '12.0pt'}}>09:00-17:00<span style={{letterSpacing: '-.15pt'}}> </span>'<span lang="HE" dir="RTL">ו</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.05pt'}}><span dir="LTR" /> </span>-<span style={{letterSpacing: '-.1pt'}}> </span><span lang="HE" dir="RTL" style={{letterSpacing: '-.2pt'}}>פתוח</span></span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '.55in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>קיבוץ</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>אילון</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', lineHeight: '11.7pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>חומוס</span><span lang="HE" style={{fontSize: '12.0pt'}}> אבו<span style={{letterSpacing: '-.1pt'}}> </span>ברי<span style={{letterSpacing: '-.1pt'}}> </span></span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />+</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', lineHeight: '10.9pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>משלוחים</span></p>
                </td>
                <td width={122} valign="top" style={{width: '91.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '11.7pt', marginBottom: '.0001pt', marginLeft: '12.35pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>חומוסיה</span></b></p>
                </td>
              </tr>
              <tr style={{height: '11.75pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '11.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.4pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>א</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-<span style={{letterSpacing: '-.25pt'}}>'</span></span><span lang="HE" style={{fontSize: '12.0pt'}}>ש</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />'</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span lang="HE" style={{fontSize: '12.0pt'}}>מוצאי<span style={{letterSpacing: '-.25pt'}}> </span>שבת<span style={{letterSpacing: '-.2pt'}}> </span></span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />14:00-23:00</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '.55in', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>קיבוץ</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>אילון</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>דארצ</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}><span dir="LTR" />'</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>ה</span></p>
                </td>
                <td width={122} valign="top" style={{width: '91.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '11.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '11.7pt', marginBottom: '.0001pt', marginLeft: '12.45pt', textAlign: 'center', lineHeight: '10.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>פיצריית</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.25pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>בוטיק</span></b></p>
                </td>
              </tr>
              <tr style={{height: '43.25pt'}}>
                <td width={200} valign="top" style={{width: '149.95pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '43.25pt'}}>
                  <p className="TableParagraph" style={{marginTop: '.05pt', marginRight: '4.55pt', marginBottom: '.0001pt', marginLeft: '5.4pt', lineHeight: '10.8pt'}}><span style={{letterSpacing: '-.1pt'}}>052-6363199</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.35pt', textAlign: 'center', lineHeight: '10.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{letterSpacing: '-.2pt'}}>פעיל</span><span lang="HE" style={{letterSpacing: '-.25pt'}}> </span><span lang="HE">בימי<span style={{letterSpacing: '-.3pt'}}> </span>א</span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />-</span><span lang="HE">ד<span style={{letterSpacing: '-.25pt'}}> </span>בשעות<span style={{letterSpacing: '-.35pt'}}>
                      </span></span><span dir="LTR" /><span dir="LTR"><span dir="LTR" />08:30-16:30</span></p>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '4.6pt', marginBottom: '.0001pt', marginLeft: '5.4pt', lineHeight: '10.8pt'}}>08:30-19:00<span style={{letterSpacing: '-.1pt'}}> </span>-<span lang="HE" dir="RTL">ה</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.1pt'}}><span dir="LTR" />
                    </span><span lang="HE" dir="RTL" style={{letterSpacing: '-.25pt'}}>ימי</span></p>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '4.65pt', marginBottom: '.0001pt', marginLeft: '5.4pt', lineHeight: '9.8pt'}}>14:00<span style={{letterSpacing: '-.2pt'}}> </span>–<span style={{letterSpacing: '-.1pt'}}> </span>08:30
                    –<span style={{letterSpacing: '-.1pt'}}> </span>'<span lang="HE" dir="RTL">ו</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.05pt'}}><span dir="LTR" />
                    </span><span lang="HE" dir="RTL" style={{letterSpacing: '-.25pt'}}>ימי</span></p>
                </td>
                <td width={191} valign="top" style={{width: '143.3pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '43.25pt'}}>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '16.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '38.85pt', marginBottom: '.0001pt', marginLeft: '39.35pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>קיבוץ </span><span lang="HE" style={{fontSize: '12.0pt'}}>אילון</span></p>
                </td>
                <td width={166} valign="top" style={{width: '124.6pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '43.25pt'}}>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '16.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '23.8pt', marginBottom: '.0001pt', marginLeft: '24.55pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>מיקה</span></p>
                </td>
                <td width={122} valign="top" style={{width: '91.8pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '43.25pt'}}>
                  <p className="TableParagraph" align="right" dir="RTL" style={{marginTop: '9.9pt', marginRight: '10.25pt', marginBottom: '.0001pt', marginLeft: '11.35pt', textAlign: 'left', textIndent: '11.4pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>בית קפה</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />+</span></b><span dir="RTL" /><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}><span dir="RTL" /> סדנאות<span style={{letterSpacing: '-.7pt'}}> </span>שוקולד</span></b></p>
                </td>
              </tr>
            </tbody></table>
          <p className="MsoNormal" align="center" dir="RTL" style={{marginTop: '9.25pt', marginRight: '141.05pt', marginBottom: '1.1pt', marginLeft: '131.4pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', letterSpacing: '-.1pt'}}>משלוחים</span></b></p>
          <table className="responsive-table" border={1} cellSpacing={0} cellPadding={0} style={{marginLeft: '5.6pt', borderCollapse: 'collapse', border: 'none'}}>
            <tbody><tr style={{height: '13.75pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '.7in', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.1pt'}}>טלפון</span></b></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '10.75pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.1pt'}}>מיקום</span></b></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', border: 'solid black 1.0pt', borderLeft: 'none', background: '#D9D9D9', padding: '0in 0in 0in 0in', height: '13.75pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.6pt', textAlign: 'center', lineHeight: '12.75pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '14.0pt', color: 'black', letterSpacing: '-.25pt'}}>שם</span></b></p>
                </td>
              </tr>
              <tr style={{height: '35.5pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '35.5pt'}}>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt', lineHeight: '11.75pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>055-9961773</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.05pt', marginRight: '49.5pt', marginBottom: '.0001pt', marginLeft: '50.5pt', textAlign: 'center', lineHeight: '11.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>חלבי</span></b><b><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />(</span></b><b><span lang="HE" style={{fontSize: '12.0pt'}}>כשר</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt'}}>מהדרין</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />)</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt', textAlign: 'center', lineHeight: '10.9pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>פעיל</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>בימי<span style={{letterSpacing: '-.05pt'}}> </span>א</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-'</span><span lang="HE" style={{fontSize: '12.0pt'}}>ה</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />'</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>החל<span style={{letterSpacing: '-.05pt'}}> </span>מ</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />17:00</span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '35.5pt'}}>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>מושב</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.35pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>נטועה</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '35.5pt'}}>
                  <p className="TableParagraph" align="left" style={{textAlign: 'left'}}><b><span style={{fontSize: '12.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.75pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.2pt'}}>פיצה</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>א</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />"</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>ש<span style={{letterSpacing: '-.1pt'}}> </span></span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.15pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>כשר</span></b></p>
                </td>
              </tr>
              <tr style={{height: '23.6pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt', lineHeight: '11.7pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>053-2306073</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '49.55pt', marginBottom: '.0001pt', marginLeft: '50.5pt', textAlign: 'center', lineHeight: '10.9pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>מינימום</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>הזמנה</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />100</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />,₪</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>פעיל<span style={{letterSpacing: '-.15pt'}}> </span>בכל<span style={{letterSpacing: '-.15pt'}}> </span>השבוע</span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '9.95pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>קיבוץ</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>אילון</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.6pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.25pt'}}>רן</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.05pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>סושי</span></b></p>
                </td>
              </tr>
              <tr style={{height: '23.6pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.25pt', lineHeight: '11.7pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>051-576-</span><span style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>0313</span></p>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '49.6pt', marginBottom: '.0001pt', marginLeft: '50.5pt', lineHeight: '10.9pt'}}><span style={{fontSize: '12.0pt'}}>12:30-21:30<span style={{letterSpacing: '-.25pt'}}> </span>'<span lang="HE" dir="RTL">ה</span><span dir="LTR" /><span dir="LTR" />-'<span lang="HE" dir="RTL">א</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.25pt'}}><span dir="LTR" /> <span dir="RTL">ימי</span></span></span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>גורן</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.7pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}>המבורגריה</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.15pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>משק<span style={{letterSpacing: '.05pt'}}> </span></span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />158</span></b></p>
                </td>
              </tr>
              <tr style={{height: '23.5pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '23.5pt'}}>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt', lineHeight: '11.75pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>050-9040521</span></p>
                  <p className="TableParagraph" style={{marginTop: '0in', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt', lineHeight: '10.8pt'}}><span style={{fontSize: '12.0pt'}}>16:00<span style={{letterSpacing: '-.2pt'}}> </span><span lang="HE" dir="RTL">עד</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.15pt'}}><span dir="LTR" /> </span>'<span lang="HE" dir="RTL">ו</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '-.1pt'}}><span dir="LTR" />
                      </span><span lang="HE" dir="RTL">יום</span><span dir="LTR" /><span lang="HE" style={{letterSpacing: '2.5pt'}}><span dir="LTR" /> </span>12:00-24:00-<span style={{letterSpacing: '-.15pt'}}> </span><span lang="HE" dir="RTL">ה</span><span dir="LTR" /><span dir="LTR" />-<span style={{letterSpacing: '-.25pt'}}>'<span lang="HE" dir="RTL">א</span></span></span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.5pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '9.95pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>זרעית</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '23.5pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.8pt', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.85pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}>-<span style={{letterSpacing: '-.2pt'}}>KUBU</span></span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>סנדביץ</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />'</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.1pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>בר<span style={{letterSpacing: '-.15pt'}}> </span>כשר</span></b></p>
                </td>
              </tr>
              <tr style={{height: '23.6pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" style={{marginTop: '5.9pt', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>050-5427555</span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.05pt', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', lineHeight: '11.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>משלוח</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', lineHeight: '10.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>*</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>בתיאום </span><span lang="HE" style={{fontSize: '12.0pt'}}>מראש</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.9pt', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.85pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.2pt'}}>מירי</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.25pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>ארוחות<span style={{letterSpacing: '-.3pt'}}> </span>ביתיות</span></b></p>
                </td>
              </tr>
              <tr style={{height: '23.6pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" style={{marginTop: '5.9pt', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>050-3341700</span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.05pt', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', lineHeight: '11.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>משלוח</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', lineHeight: '10.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>*</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>בתיאום </span><span lang="HE" style={{fontSize: '12.0pt'}}>מראש</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '23.6pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '5.9pt', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.8pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.2pt'}}>זהבה</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.25pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>ארוחות<span style={{letterSpacing: '-.25pt'}}> </span>ביתיות</span></b></p>
                </td>
              </tr>
              <tr style={{height: '51.9pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '51.9pt'}}>
                  <p className="TableParagraph" style={{marginTop: '2.3pt', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.35pt', lineHeight: '11.8pt'}}><span style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>054-6870446</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '49.5pt', marginBottom: '.0001pt', marginLeft: '50.5pt', textAlign: 'center', lineHeight: '11.8pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>(</span></b><b><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>כשר</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt'}}>ללא<span style={{letterSpacing: '-.2pt'}}> </span>תעודת<span style={{letterSpacing: '-.2pt'}}>
                        </span>כשרות</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />)</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.1pt', marginRight: '49.65pt', marginBottom: '.0001pt', marginLeft: '50.5pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontSize: '12.0pt'}}>*</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.35pt'}}><span dir="RTL" /> </span><span lang="HE" style={{fontSize: '12.0pt'}}>הזמנה<span style={{letterSpacing: '-.2pt'}}> </span>ליום<span style={{letterSpacing: '-.3pt'}}> </span>שישי<span style={{letterSpacing: '-.2pt'}}> </span>ניתן<span style={{letterSpacing: '-.3pt'}}> </span>לעשות<span style={{letterSpacing: '-.35pt'}}> </span>עד<span style={{letterSpacing: '-.35pt'}}> </span>יום<span style={{letterSpacing: '-.3pt'}}> </span>רביעי</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />.</span><span dir="RTL" /><span style={{fontSize: '12.0pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />*</span><span dir="RTL" /><span lang="HE" style={{fontSize: '12.0pt'}}><span dir="RTL" /> מגשי פירות ומארזי
                      בראנץ</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-'</span><span dir="RTL" /><span lang="HE" style={{fontSize: '12.0pt'}}><span dir="RTL" /> יום לפני</span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '51.9pt'}}>
                  <p className="TableParagraph" align="left" style={{marginTop: '.3pt', textAlign: 'left'}}><b><span style={{fontSize: '14.0pt'}}>&nbsp;</span></b></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>משלוח</span></p>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '.05pt', marginRight: '10.05pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span dir="LTR" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>*</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>בתיאום </span><span lang="HE" style={{fontSize: '12.0pt'}}>מראש</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '51.9pt'}}>
                  <p className="TableParagraph" align="right" dir="RTL" style={{marginTop: '8.2pt', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '38.9pt', textAlign: 'left', textIndent: '-25.0pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>אתי<span style={{letterSpacing: '-.5pt'}}> </span>ארוחות<span style={{letterSpacing: '-.5pt'}}> </span>שף</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />-</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', color: 'black', letterSpacing: '1.7pt'}}><span dir="RTL" /> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>אוכל חלבי</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt', color: 'black'}}><span dir="LTR" />\</span></b><span dir="RTL" /><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}><span dir="RTL" /> בשרי</span></b></p>
                </td>
              </tr>
              <tr style={{height: '11.85pt'}}>
                <td width={393} valign="top" style={{width: '294.9pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0in 0in 0in 0in', height: '11.85pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '35.8pt', marginBottom: '.0001pt', marginLeft: '36.45pt', textAlign: 'center', lineHeight: '10.85pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>מסעדת </span><span lang="HE" style={{fontSize: '12.0pt'}}>פיצות<span style={{letterSpacing: '-.25pt'}}> </span>ובשרים<span style={{letterSpacing: '-.2pt'}}> </span>פתוחים<span style={{letterSpacing: '-.2pt'}}>
                      </span>ב</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-'</span><span lang="HE" style={{fontSize: '12.0pt'}}>ש</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />'</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><span style={{fontSize: '12.0pt', letterSpacing: '.05pt'}}><span dir="RTL" /> </span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />04-987-0948</span></p>
                </td>
                <td width={121} valign="top" style={{width: '90.7pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 0in 0in 0in', height: '11.85pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '9.9pt', marginBottom: '.0001pt', marginLeft: '.15in', textAlign: 'center', lineHeight: '10.85pt', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}>פסוטה</span></p>
                </td>
                <td width={171} valign="top" style={{width: '128.4pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', background: '#F1F1F1', padding: '0in 0in 0in 0in', height: '11.85pt'}}>
                  <p className="TableParagraph" dir="RTL" style={{marginTop: '0in', marginRight: '5.8pt', marginBottom: '.0001pt', marginLeft: '6.85pt', textAlign: 'center', lineHeight: '10.85pt', direction: 'rtl', unicodeBidi: 'embed'}}><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.2pt'}}>פיצה</span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black', letterSpacing: '-.35pt'}}> </span></b><b><span lang="HE" style={{fontSize: '12.0pt', color: 'black'}}>מרגריטה</span></b></p>
                </td>
              </tr>
            </tbody></table>
          <p className="MsoNormal" align="center" dir="RTL" style={{marginTop: '4.65pt', marginRight: '141.05pt', marginBottom: '.0001pt', marginLeft: '144.55pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.2pt'}}>שירות</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.1pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>לאורח</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><b><span lang="HE" style={{fontSize: '12.0pt'}}><span dir="RTL" /> עד<span style={{letterSpacing: '-.15pt'}}> </span>השעה </span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />18:30</span></b><span dir="RTL" /><b><span style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}><span dir="RTL" /> </span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />(</span></b><b><span lang="HE" style={{fontSize: '12.0pt'}}>למעט מקרים<span style={{letterSpacing: '-.15pt'}}> </span>דחופים</span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />)</span></b></p>
          <p className="MsoNormal" align="center" dir="RTL" style={{marginTop: '.9pt', marginRight: '141.05pt', marginBottom: '.0001pt', marginLeft: '144.45pt', textAlign: 'center', direction: 'rtl', unicodeBidi: 'embed'}}><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.25pt'}}>קוד</span><span lang="HE" style={{fontSize: '12.0pt', letterSpacing: '-.15pt'}}> </span><span lang="HE" style={{fontSize: '12.0pt'}}>לפתיחת<span style={{letterSpacing: '-.1pt'}}> </span>השער</span><span dir="LTR" /><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />-</span><span dir="RTL" /><b><span style={{fontSize: '12.0pt', letterSpacing: '-.05pt'}}><span dir="RTL" /> </span></b><span dir="LTR" /><b><span dir="LTR" style={{fontSize: '12.0pt'}}><span dir="LTR" />2122</span></b></p>
          <p className="MsoNormal" align="center" style={{marginTop: '1.05pt', marginRight: '141.0pt', marginBottom: '.0001pt', marginLeft: '144.55pt', textAlign: 'center'}}><b><span style={{fontSize: '12.0pt'}}>04-9806006<span style={{letterSpacing: '-.1pt'}}> </span></span></b><span style={{fontSize: '12.0pt'}}>-<span lang="HE" dir="RTL" style={{letterSpacing: '-.2pt'}}>קבלה</span></span></p>
        </div>
      </div>
      <SuiteType setBgColor={setBgColor} />
      <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>
      <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

    </div>
           

  );
}

export default Restaurants;