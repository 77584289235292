import React, { useEffect, useState } from 'react';
// import './DomiIntroduction.css';

const Introduction = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./DomiIntroduction.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  return (
    <div className="introduction-container">
      <div className="introduction-content">
        <h1 className="introduction-header">
          חופשה יוקרתית לשתי משפחות 
        </h1>
        
        <div style={{ position: 'relative', padding: '1em' }}>
          <span style={{ fontSize: '3em', position: 'absolute', right: '-5px', top: '22px' }}>&ldquo;</span>
          <p className="introduction-text" >הסוויטה הדו משפחתית היא יצירת פאר מבחינת הנוחות והכיף לשתי משפחות המגיעות יחד לחופשה, הביחד הוא מדהים בבריכה ובכל המתחם ובגינה, ולכל משפחה יש את הפינה שלה בסוויטה, היה לנו מושלם!</p>
          <span style={{ fontSize: '3em', position: 'absolute', left: '10px', bottom: '-30px' }}>&rdquo;</span>
        </div>
        {/* <a href="#welcome" className="introduction-button">
          הזמנת סוויטה
        </a> */}
      </div>
    </div>
  );
};

export default Introduction;
