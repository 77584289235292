import React, { useEffect, useState } from 'react';
import './LuxIntroduction.css';

const Introduction = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./LuxIntroduction.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  return (
    <div className="introduction-container">
      <div className="introduction-content">
        <h1 className="introduction-header">
          השראה דרמטית, ועונג צרוף בכל פינה
        </h1>
        
        <div style={{ position: 'relative', padding: '1em' }}>
          <span style={{ fontSize: '3em', position: 'absolute', right: '-5px', top: '22px' }}>&ldquo;</span>
          <p className="introduction-text" >רואים ומרגישים מחשבה בכל פרט. בעיצוב הלא גנרי, בגודל, בפרטים העיצוביים המרכיבים את הסוויטה, במוזיקה המתנגנת כשאתה נכנס לחדר, בטמפרטורה המדוייקת שמחכה לך בחדר, בבריכה ובג'קזי, בשוקולדים במקרר, בהכל. גם כל הסביבה שמחוץ לחדר מטופחת ומדוייקת. הכל יפה ורחב ידיים ולא עמוס. פשוט נעים. מקום שמשרה שלווה ברמה הכי גבוה ומפנקת שיש.
          </p>
          <span style={{ fontSize: '3em', position: 'absolute', left: '10px', bottom: '-30px' }}>&rdquo;</span>
        </div>
        {/* <a href="#welcome" className="introduction-button">
          הזמנת סוויטה
        </a> */}
      </div>
    </div>
  );
};

export default Introduction;
