import React, { useState, useEffect } from 'react';
// import './LuxurySuites.css';
//import './App.css';
import { FaArrowUp } from 'react-icons/fa';


// Components
//import Header from './components/Header';
import NavigationBar from './NavigationBar';
import Welcome from './LuxurySuite/LuxWelcome';
import Introduction from './LuxurySuite/LuxIntroduction';
//import ImageGallery from './ImageGallery';
//import CallToAction from './CallToAction';
//import Accommodations from './Accommodations';
//import Services from './Services';
//import Recommendations from './Recommendations';
//import Contact from './Contact';
import FloatingButton from './FloatingButton';
import Gallery from './LuxurySuite/LuxGallery';
import GalleryIos from './LuxurySuite/LuxGalleryIOS';
import FeaturesIcons from './LuxurySuite/LuxFeaturesIcons';
import SuiteType from './SuiteType';

import { animateScroll as scroll } from 'react-scroll';

const HamakomSuites = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./LuxurySuites.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bgColor, setBgColor] = useState('white');
  const [activeDiv, setActiveDiv] = useState(null);
  //this is a temp patch for the observer loop annoying issue:
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    })
  }, [])
  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);

  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };
  const isIOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  return (
    
    <div className="App luxsuitesapp" id="lux1">
     
      <link rel="stylesheet" href="https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.css" />
      <link rel="stylesheet" href="https://bookingresults.com/2020.hamakom-suites/Content/new_be/SBoxCustomTheme.css" />

{/*       <Header scrollPosition={scrollPosition} /> */}
      <Welcome scrollPosition={scrollPosition} />
      <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <Introduction />
      {isIOS() ? <GalleryIos /> : <Gallery />}
      <FeaturesIcons />
      <SuiteType setBgColor={setBgColor} />

{/*
//      <ImageGallery />
//      <CallToAction />
//      <Accommodations />
//      <Services />
//      <Recommendations />
//      <Contact />  */}
        <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>

        <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

    </div>
  );
}

export default HamakomSuites;