import React, { useState, useEffect,useRef } from 'react';
import './NavigationBar.css';
import { MdMenu, MdClose } from 'react-icons/md';
import NavigationPage from './NavigationPage';
import logo from '../assets/photos/logo-missive-green-final.png'; 
import logo_gold from '../assets/photos/logo-missive-bright.png'; 
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import arrowlong2 from '../assets/photos/scroll2.png'; 
import { Link, useLocation  } from 'react-router-dom';
import CustomLink from './CustomLink';

const NavigationBar = ({ bgColor,activeDiv, setActiveDiv }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [shrinkNav, setShrinkNav] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    let borgerColor;

useEffect(() => {
  let lastScrollY = 0;
  let ticking = false;

  const updateScrollState = () => {
    const currentScrollY = window.scrollY;
    const threshold = 25;

    if (currentScrollY > lastScrollY + threshold) {
      setShrinkNav(true);
    } else if (currentScrollY + threshold < lastScrollY) {
      setShrinkNav(false);
    }

    lastScrollY = currentScrollY;
    ticking = false;
  };

  const handleScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(updateScrollState);
      ticking = true;
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  
    const toggleNavigation = () => {
        setIsNavOpen(!isNavOpen);
    };
    if(bgColor === 'white') { borgerColor='#2d2d2d'} else { borgerColor='white'}

    // ---reservation system for desktop:
    const [showBookingOverlay, setShowBookingOverlay] = useState(false);
    const sboxRef = useRef(null);   

    const doClickSearchNav = ()=> {
        setShowBookingOverlay(true);
        setActiveDiv('div2');
    }
    
    // --END reservation system for desktop:
    // {console.log('divid:')}
    const location = useLocation();
    //{console.log(location)}
    useEffect(() => {
      if (location.state && location.state.scrollTo) {
        const sectionId = location.state.scrollTo;
  
        // Add a delay to allow the page content to render
        setTimeout(() => {
          // Use the native DOM API to scroll to the element
          const element = document.getElementById(sectionId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    }, [location]);
    

    return (
      <nav className={`nav-bar ${shrinkNav ? 'shrink' : ''}`}  style={{ backgroundColor: bgColor, borderColor:bgColor }}>
          {/* Title */}
          {/* <h1 className="title">Your Title</h1> */}
          {bgColor === 'white' ? 
            <Link to={'/'} onClick={() => {window.scroll(0, 0);}}><img className={`logoclass ${shrinkNav ? 'shrink' : ''}`} src={logo} alt="Logo" /></Link> :
            <Link to={'/'} onClick={() => {window.scroll(0, 0);}}><img className={`logoclass ${shrinkNav ? 'shrink' : ''}`} src={logo_gold} alt="Logo" /></Link>
          }
          {/* Desktop Navigation */}
          <ul className="desktop-menu">
              <li className={activeMenu === 'home' ? 'active' : ''} onClick={() => setActiveMenu('home')}><CustomLink to="/" section="welcome">ראשי</CustomLink></li>
              {/* <li className={activeMenu === 'orders' ? 'active' : ''} onClick={() => setActiveMenu('orders')}><a href="#orders" style={{ color: borgerColor }}>הזמנות</a></li> */}
              <li className={activeMenu === 'photos' ? 'active' : ''} onClick={() => setActiveMenu('photos')}><CustomLink to="/" section="gallery-photos" style={{ color: borgerColor }}>תמונות</CustomLink></li>
              <li className={activeMenu === 'suites' ? 'active' : ''} onClick={() => setActiveMenu('suites')}><CustomLink to="/" section="room-type" style={{ color: borgerColor }}>הסוויטות</CustomLink></li>
              <li className={activeMenu === 'estate' ? 'active' : ''} onClick={() => setActiveMenu('estate')}><Link to={'/LuxuryVilla'} onClick={() => {window.scroll(0, 0);}} style={{ color: borgerColor }}>אחוזת הקשתות</Link></li>
              <li className={activeMenu === 'virtual-tour' ? 'active' : ''} onClick={() => setActiveMenu('virtual-tour')}><Link to={'/VirtualTour'} onClick={() => {window.scroll(0, 0);}} style={{ color: borgerColor }}>סיור וירטואלי</Link></li>
          </ul>
          

          {/* Hamburger Icon for Mobile */}
          <div className="hamburger-icon" onClick={toggleNavigation}  style={{ color: borgerColor }} >
              {isNavOpen ? <MdClose /> : <MdMenu />}
          </div>

          {/* Mobile Navigation */}
          <div className={`navigation-page ${isNavOpen ? 'open' : ''}`}>
              <NavigationPage closeNav={toggleNavigation} setIsNavOpen={setIsNavOpen} />
          </div>
          {/* reservasion system for desktop */}

            <button className="floating-button-desk" onClick = {doClickSearchNav} style={{ color: borgerColor }}>
            הזמנת חופשה
            <img className='arrowlong' src={arrowlong2} alt='arrow'/>
            </button>

            <div className={`booking-overlay ${showBookingOverlay ? 'show' : ''}`} id="booking-overlay2">
                <button className="close-button" onClick={() => {setShowBookingOverlay(false);}}><MdClose /></button>
                <h3> בחרו תאריכים לחופשה</h3>
                <div className='bo-wrap'>
                    {activeDiv === 'div2' && <div ref={sboxRef} id="sbox" data-url="https://bookingresults.com/2020.hamakom-suites/actionUrl?lang=heb"></div>}
                    
                
                </div>
                {/* Include booking form or other content here */}
            </div>
            <a className="phone-bar" href="tel:123 456 7890"><span>04-9806-006📞</span></a>

      </nav>
  );
};

export default NavigationBar;
