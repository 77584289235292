
import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaArrowUp } from 'react-icons/fa';

import NavigationBar from './NavigationBar';
import FloatingButton from './FloatingButton';
import SuiteType from './SuiteType';
import kosher_img from '../assets/photos/kosher.jpg'; 

// import './Pages.css';




const Kosher = () => {
  useEffect(() => {
    const importCss = async () => {
      try {
        await import('./Pages.css');//replacing the css with the css of the gallery of the suites
        console.log("Component XYZ is mounted!");
      } catch (err) {}
    };
    importCss();
  }, []);
  const [activeDiv, setActiveDiv] = useState(null);
  const [bgColor, setBgColor] = useState('white');

  useEffect(() => {
    if (activeDiv) {
      const script = document.createElement('script');
      script.src = 'https://bookingresults.com/Silverbyte.cdn/searchbox/react/1.6.3.0/searchbox.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [activeDiv]);
  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    
    <div className="kosher" id="kosher">
      <NavigationBar bgColor={bgColor} activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <div className='kosher-cont'>
        <img className='kosher-img' src={kosher_img} alt='arrow'/>

      </div>
      <SuiteType setBgColor={setBgColor} />
      <FloatingButton activeDiv={activeDiv} setActiveDiv={setActiveDiv}/>
      <button className="scroll-top-button" onClick={handleScrollToTop}><FaArrowUp /></button>

    </div>
           

  );
}

export default Kosher;